<template>

  <div>
    <b-row
      class="content-header"
    >
      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="7"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="custom-header-title float-left pr-1 mb-0">
              {{ digitalForm.name }}
            </h2>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row
      class="content-header v-sticky-sidebar-container service_request_task mx-3"
      style="background: #E0E8F2; border-radius: 6px;"
    >
      <b-col
        class="content-header-right d-md-block df-padding"
        md="12"
        cols="12"
      >
        <!-- Table Container Card -->
        <b-card
          no-body
          class="mb-0"
        >
          <div class="urn-form-wrapper m-4 ">
            <div class="d-form-header d-flex justify-content-between mb-75">
              <div class="d-form-logo_section" />
              <div class="d-form-no_section text-right">
                <h5>
                  {{ digitalForm.formNumber }}
                </h5>
                <h5>
                  {{ digitalForm.revision }}
                </h5>
              </div>
            </div>
            <div class="urn-repository-order_section">
              <div class="b-form-section-title my-2">
                <h3>ANCESTRAL TABLETS ENGRAVING NOTICE <span>牌位刻碑表格</span></h3>
              </div>
              <b-row>
                <b-col md="8">
                  <div class="d-form-border my-2">
                    <b-row>
                      <b-col
                        md="7"
                        class="d-flex justify-content-center"
                      >
                        <div class="p-2">
                          <h6 class="ancestral-table-section-title">
                            家族中堂
                          </h6>
                          <div class="d-flex">
                            <div class="vertical-single-box-wrapper">
                              <div
                                class="vertical-single-box"
                                :class="isInputValue ? 'bg-white' : ''"
                              >
                                <div
                                  v-if="isInputValue"
                                  class="vertical-single-box-content"
                                >
                                  <p>长生</p>
                                  <h5>許嘉鈴</h5>
                                  <p>2</p>
                                </div>
                                <div
                                  v-else
                                  class="vertical-single-box-content-empty"
                                >
                                  2
                                </div>
                              </div>
                              <div
                                class="vertical-single-box"
                                :class="isInputValue ? 'bg-white' : ''"
                              >
                                <div
                                  v-if="isInputValue"
                                  class="vertical-single-box-content"
                                >
                                  <p>长生</p>
                                  <h5>許嘉鈴</h5>
                                  <p>4</p>
                                </div>
                                <div
                                  v-else
                                  class="vertical-single-box-content-empty"
                                >
                                  4
                                </div>
                              </div>
                              <div
                                class="vertical-single-box"
                                :class="isInputValue ? 'bg-white' : ''"
                              >
                                <div
                                  v-if="isInputValue"
                                  class="vertical-single-box-content"
                                >
                                  <p>长生</p>
                                  <h5>許嘉鈴</h5>
                                  <p>6</p>
                                </div>
                                <div
                                  v-else
                                  class="vertical-single-box-content-empty"
                                >
                                  6
                                </div>
                              </div>
                            </div>
                            <div class="vertical-single-box-wrapper-with-text">
                              <div class="vertical-single-box-heading">
                                <h4>佛光</h4>
                                <h4>接引</h4>
                              </div>
                              <div
                                class="vertical-single-box-with-text"
                                :class="isInputValue ? 'bg-white' : ''"
                              >
                                <div
                                  v-if="isInputValue"
                                  class="vertical-single-box-content"
                                >
                                  <p>长生</p>
                                  <h5>許嘉鈴</h5>
                                  <p>7</p>
                                </div>
                                <div
                                  v-else
                                  class="vertical-single-box-content-empty"
                                >
                                  7
                                </div>
                              </div>
                              <div class="vertical-single-box-heading-bottom">
                                <h4>門</h4>
                                <h4>歷</h4>
                                <h4>代</h4>
                                <h4>祖</h4>
                                <h4>先</h4>
                                <h4>位</h4>
                              </div>
                            </div>
                            <div class="vertical-single-box-wrapper">
                              <div
                                class="vertical-single-box"
                                :class="isInputValue ? 'bg-white' : ''"
                              >
                                <div
                                  v-if="isInputValue"
                                  class="vertical-single-box-content"
                                >
                                  <p>长生</p>
                                  <h5>許嘉鈴</h5>
                                  <p>1</p>
                                </div>
                                <div
                                  v-else
                                  class="vertical-single-box-content-empty"
                                >
                                  1
                                </div>
                              </div>
                              <div
                                class="vertical-single-box"
                                :class="isInputValue ? 'bg-white' : ''"
                              >
                                <div
                                  v-if="isInputValue"
                                  class="vertical-single-box-content"
                                >
                                  <p>长生</p>
                                  <h5>許嘉鈴</h5>
                                  <p>3</p>
                                </div>
                                <div
                                  v-else
                                  class="vertical-single-box-content-empty"
                                >
                                  3
                                </div>
                              </div>
                              <div
                                class="vertical-single-box"
                                :class="isInputValue ? 'bg-white' : ''"
                              >
                                <div
                                  v-if="isInputValue"
                                  class="vertical-single-box-content"
                                >
                                  <p>长生</p>
                                  <h5>許嘉鈴</h5>
                                  <p>5</p>
                                </div>
                                <div
                                  v-else
                                  class="vertical-single-box-content-empty"
                                >
                                  5
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-col>
                      <b-col
                        class="vertical-box-right-section-block d-flex justify-content-center"
                        md="5"
                      >
                        <div class="py-2">
                          <h6 class="ancestral-table-section-title">
                            無中堂
                          </h6>
                          <div class="vertical-single-box-right-section-block">
                            <div class="vertical-single-box-right-section-heading">
                              <h4>佛光</h4>
                              <h4>接引</h4>
                            </div>
                            <div class="vertical-single-box-right-section-main-wrapper">
                              <div class="vertical-single-box-right-section-wrapper">
                                <div class="vertical-single-box-right-section">
                                  <div
                                    class="vertical-single-box-right-section-content-empty"
                                  >
                                    2
                                  </div>
                                </div>
                                <div class="vertical-single-box-right-section">
                                  <div
                                    class="vertical-single-box-right-section-content-empty"
                                  >
                                    4
                                  </div>
                                </div>
                                <div class="vertical-single-box-right-section">
                                  <div
                                    class="vertical-single-box-right-section-content-empty"
                                  >
                                    6
                                  </div>
                                </div>
                              </div>
                              <div class="vertical-single-box-right-section-wrapper">
                                <div class="vertical-single-box-right-section">
                                  <div
                                    class="vertical-single-box-right-section-content-empty"
                                  >
                                    1
                                  </div>
                                </div>
                                <div class="vertical-single-box-right-section">
                                  <div
                                    class="vertical-single-box-right-section-content-empty"
                                  >
                                    3
                                  </div>
                                </div>
                                <div class="vertical-single-box-right-section">
                                  <div
                                    class="vertical-single-box-right-section-content-empty"
                                  >
                                    5
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="vertical-single-box-right-section-heading-bottom">
                              <h4>之</h4>
                              <h4>蓮</h4>
                              <h4>位</h4>
                            </div>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
                <b-col md="4">
                  <div class="d-form-border my-2">
                    <div class="d-form-photo-status-block">
                      <div class="d-form-heading-block">
                        <h4>
                          Photo Received
                          <br>
                          <span>照片提供</span>
                        </h4>
                      </div>
                      <b-form-group
                        label="Received By 收件:"
                        label-for="input-default"
                      >
                        <div
                          class="empty-input__text"
                        ><p
                          emptyDiv="true"
                          data-text="Enter"
                        /></div>
                      </b-form-group>
                      <b-form-group
                        label="Date 日期:"
                        label-for="input-default"
                      >
                        <div
                          class="empty-input__text"
                        ><p
                          emptyDiv="true"
                          data-text="DD/MM/YYYY"
                        /></div>
                      </b-form-group>
                      <div class="d-form-heading-block mt-1">
                        <h4>
                          Photo Returned
                          <br>
                          <span>照片归还</span>
                        </h4>
                      </div>
                      <b-form-group
                        label="Claimed By 照片领回:"
                        label-for="input-default"
                      >
                        <div
                          class="empty-input__text"
                        ><p
                          emptyDiv="true"
                          data-text="Enter"
                        /></div>
                      </b-form-group>
                      <b-form-group
                        label="Date 日期:"
                        label-for="input-default"
                      >
                        <div
                          class="empty-input__text"
                        ><p
                          emptyDiv="true"
                          data-text="DD/MM/YYYY"
                        /></div>
                      </b-form-group>
                      <div class="d-form-heading-block mt-1">
                        <h4>Remarks 备注</h4>
                      </div>
                      <div>
                        <p>All Chinese Character on the plaque must be in traditional Chinese text.<br><span>碑文均採繁體</span></p>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <div class="d-form-photo-status-block">
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="2"
                      label-for="input-default"
                    >
                      <template #label>
                        <span>Purchaser or Next of Kin Name*</span>
                        <br>
                        <span>购买者或至亲姓名</span>
                      </template>
                      <div
                        class="empty-input__text d-form-input-custom-height"
                      ><p
                        emptyDiv="true"
                        data-text="Enter"
                      /></div>
                    </b-form-group>
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="2"
                      label-for="input-default"
                    >
                      <template #label>
                        <span>File No.*</span>
                        <br>
                        <span>文件号码</span>
                      </template>
                      <div
                        class="empty-input__text d-form-input-custom-height"
                      ><p
                        emptyDiv="true"
                        data-text="Enter"
                      /></div>
                    </b-form-group>
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="2"
                      label-for="input-default"
                    >
                      <template #label>
                        <span>Agent Name*</span>
                        <br>
                        <span>代理姓名</span>
                      </template>
                      <div
                        class="empty-input__text d-form-input-custom-height"
                      ><p
                        emptyDiv="true"
                        data-text="Enter"
                      /></div>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col md="6">
                  <div class="d-form-photo-status-block">
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="2"
                      label-for="input-default"
                    >
                      <template #label>
                        <span>Contact No.</span>
                        <br>
                        <span>联络号码</span>
                      </template>
                      <div
                        class="empty-input__text"
                      ><p
                        emptyDiv="true"
                        data-text="Enter"
                      /></div>
                    </b-form-group>
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="2"
                      label-for="input-default"
                    >
                      <template #label>
                        <span>NRIC*:</span>
                        <br>
                        <span>身份证号码</span>
                      </template>
                      <div
                        class="empty-input__text"
                      ><p
                        emptyDiv="true"
                        data-text="Enter"
                      /></div>
                    </b-form-group>
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="2"
                      label-for="input-default"
                    >
                      <template #label>
                        <span>Pedestal No.*</span>
                        <br>
                        <span>牌位编号</span>
                      </template>
                      <div
                        class="empty-input__text"
                      ><p
                        emptyDiv="true"
                        data-text="Enter"
                      /></div>
                    </b-form-group>
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="2"
                      label-for="input-default"
                    >
                      <template #label>
                        <span>Agent Contact No.</span>
                        <br>
                        <span>代理联络电话</span>
                      </template>
                      <div
                        class="empty-input__text"
                      ><p
                        emptyDiv="true"
                        data-text="Enter"
                      /></div>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label-cols="4"
                    label-cols-lg="1"
                    label-for="input-default"
                  >
                    <template #label>
                      <span>Remarks</span>
                      <br>
                      <span>备注</span>
                    </template>
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="d-form-disclaimer_text-block">
                <p>Disclaimer: I hereby confirm that all the information and characters herein are correct and authorised Mount Prajna Ltd to engrave the Plaque in accordance to this notice. The Plaque will be available after 30 days from the date of this notice.</p>
                <p>声明： 本人确定所附资料正确无误，并请遵照刻碑，本表格签署三十天后，即请安排上碑。</p>
              </div>
              <b-row class="d-form-signature_block">
                <b-col md="6">
                  <p>Purchaser’s / Next of Kin’s Signatory & Date
                    <br>
                    <span>购者或至亲签名</span>
                  </p>
                  <div
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.purchaser-sign-modal
                    class="form-signature-box"
                  >
                    <div class="form-signature-content">
                      <span
                        v-if="!isPurchaserSign"
                        class="form-signature-text"
                      >
                        Sign here
                      </span>
                      <b-img
                        v-else
                        :src="purchaserSign"
                        alt="authorized-sign"
                      />
                    </div>
                  </div>
                  <div class="signature-date-block">
                    <p>Name 姓名: </p>
                    <p>Date 日期: </p>
                  </div>
                </b-col>
                <b-col md="6">
                  <p>Completion checked
                    <br>
                    <span>碑文检查，确认签名 / 日期</span>
                  </p>
                  <div
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.completion-sign-modal
                    class="form-signature-box"
                  >
                    <div class="form-signature-content">
                      <span
                        v-if="!isCompletionSign"
                        class="form-signature-text"
                      >
                        Sign here
                      </span>
                      <img
                        v-else
                        :src="completionSign"
                        alt="authorized-sign"
                      >
                    </div>
                  </div>
                  <div class="signature-date-block">
                    <p>Name 姓名: </p>
                    <p>Date 日期: </p>
                  </div>
                </b-col>
              </b-row>
              <div class="d-form-border mt-4">
                <h5 class="d-form-office-use-title">
                  For office use 公务用栏
                </h5>
                <b-row>
                  <b-col
                    md="2"
                    class="d-form-border-right pr-0"
                  >
                    <div class="padding-20">
                      <b-form-group
                        label="Remark 备注:"
                        label-for="input-default"
                      >
                        <div
                          class="empty-input__text"
                        ><p
                          emptyDiv="true"
                          data-text="Enter"
                        /></div>
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col
                    md="5"
                    class="d-form-border-right p-0"
                  >
                    <div class="padding-20">
                      <b-form-group
                        label="Person in Charge 服务人员:"
                        label-for="input-default"
                      >
                        <div
                          class="empty-input__text"
                        ><p
                          emptyDiv="true"
                          data-text="Enter"
                        /></div>
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col
                    md="5"
                    class="pl-0"
                  >
                    <div class="padding-20">
                      <b-form-group
                        label="Installation Date 预定上位日期:"
                        label-for="input-default"
                      >
                        <div
                          class="empty-input__text"
                        ><p
                          emptyDiv="true"
                          data-text="DD/MM/YYYYY"
                        /></div>
                      </b-form-group>
                      <b-form-group
                        label="Submitted Date 刻碑资料提交日期:"
                        label-for="input-default"
                      >
                        <div
                          class="empty-input__text"
                        ><p
                          emptyDiv="true"
                          data-text="DD/MM/YYYYY"
                        /></div>
                      </b-form-group>
                      <b-form-group
                        label="Email Date 校对检查电邮日期:"
                        label-for="input-default"
                      >
                        <div
                          class="empty-input__text"
                        ><p
                          emptyDiv="true"
                          data-text="DD/MM/YYYYY"
                        /></div>
                      </b-form-group>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <!-- purchaser signature modal -->
    <b-modal
      id="purchaser-sign-modal"
      ref="purchaser-sign-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Purchaser's Signature"
      @ok="closePurchaserSignModal"
      @cancel="closePurchaserSignModal"
    >
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          @click="clearPurchaserSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="savePurchaserSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ purchaser signature modal -->
    <!-- completion signature modal -->
    <b-modal
      id="completion-sign-modal"
      ref="completion-sign-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Completion Signature"
      @ok="closeCompletionSignModal"
      @cancel="closeCompletionSignModal"
    >
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          @click="clearCompletionSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveCompletionSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ complete signature modal -->
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BButton, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BImg,
  },
  directives: {
    Ripple,
  },
  props: {
    digitalForm: {
      type: Object,
      required: true,
    },
    topSpacing: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      gender: [],
      genderOptions: [
        { text: 'Male 男', value: 'Male 男' },
        { text: 'Female 女', value: 'Female 女' },
      ],
      isInputValue: true,
      isSignature: false,
      defaultSignature: '',
      isPurchaserSign: false,
      purchaserSign: '',
      isCompletionSign: false,
      completionSign: '',
      options: {
        penColor: '#000000',
        onBegin: () => { this.$refs.signaturePad.resizeCanvas() },
      },
    }
  },
  methods: {
    closePurchaserSignModal() {
      this.$root.$emit('bv::hide::modal', 'purchaser-sign-modal', '')
    },
    clearPurchaserSign() {
      this.$refs.signaturePad.clearSignature()
    },
    savePurchaserSign() {
      const { data } = this.$refs.signaturePad.saveSignature()
      this.purchaserSign = data
      if (data) {
        this.$root.$emit('bv::hide::modal', 'purchaser-sign-modal', '')
        this.isPurchaserSign = true
      }
    },
    closeCompletionSignModal() {
      this.$root.$emit('bv::hide::modal', 'completion-sign-modal', '')
    },
    clearCompletionSign() {
      this.$refs.signaturePad.clearSignature()
    },
    saveCompletionSign() {
      const { data } = this.$refs.signaturePad.saveSignature()
      this.completionSign = data
      if (data) {
        this.$root.$emit('bv::hide::modal', 'completion-sign-modal', '')
        this.isCompletionSign = true
      }
    },
  },
}
</script>
