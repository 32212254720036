<template>
  <div>
    <b-row
      class="content-header"
    >
      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="7"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="custom-header-title float-left pr-1 mb-0">
              {{ digitalForm.name }}
            </h2>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row
      class="content-header v-sticky-sidebar-container service_request_task mx-3"
      style="background: #E0E8F2; border-radius: 6px;"
    >
      <b-col
        class="content-header-right d-md-block df-padding"
        md="12"
        cols="12"
      >
        <!-- Table Container Card -->
        <b-card
          no-body
          class="mb-0"
        >
          <div class="urn-form-wrapper m-4 ">
            <div class="orn-form-header d-flex justify-content-between mb-75">
              <div class="d-form-logo_section" />
              <div class="d-form-no_section text-right">
                <h5>
                  {{ digitalForm.formNumber }}
                </h5>
                <h5>
                  {{ digitalForm.revision }}
                </h5>
              </div>
            </div>
            <div class="urn-repository-order_section">
              <div class="b-form-section-title my-2">
                <h3>Redemption of ornament form 领取福位里的福物声明</h3>
              </div>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-cols="12"
                    label-cols-md="6"
                    label-cols-lg="3"
                    label="Redemption of ornament form (Niche lot)*:"
                    label-for="input-default"
                  >
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="4">
                  <b-form-group
                    label-cols="12"
                    label-cols-md="2"
                    label-cols-lg="1"
                    label="I*,"
                    label-for="input-default"
                  >
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
                <b-col md="8">
                  <b-form-group
                    label-cols="12"
                    label-cols-md="6"
                    label-cols-lg="4"
                    label="Purchaser/authorized representative (NRIC:)* "
                    label-for="input-default"
                  >
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="4">
                  <b-form-group
                    label-cols="12"
                    label-cols-md="2"
                    label-cols-lg="1"
                    label="I,"
                    label-for="input-default"
                  >
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
                <b-col md="8">
                  <b-form-group
                    label-cols="12"
                    label-cols-md="4"
                    label-cols-lg="2"
                    label="Next of Kin (NRIC:) "
                    label-for="input-default"
                  >
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-cols="12"
                    label-cols-md="7"
                    label-cols-lg="5"
                    label="do hereby acknowledge retrieval of the ornament of the File No*"
                    label-for="input-default"
                  >
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="d-form-warning-text-block">
                <p class="d-form-warning-text">
                  本人乃新加玻富贵山庄福位购买者 / 购买者之委托人（本人身份证号，文件号码，如上述）现领取福位里的福物。
                </p>
              </div>
              <b-row class="mt-5">
                <b-col md="6">
                  <p class="d-form-signature-title">Purchaser / Authorized Representative Signatory
                    <br>
                    <span>购买者/受委托领瓮人签名</span>
                  </p>
                  <div
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.authorized-sign-modal
                    class="form-signature-box"
                  >
                    <div class="form-signature-content">
                      <span
                        v-if="!authorizedSign"
                        class="form-signature-text"
                      >
                        Sign here
                      </span>
                      <b-img
                        v-else
                        :src="authorizedSignSrc"
                        alt="authorized-sign"
                      />
                    </div>
                  </div>
                  <div class="signature-date-block">
                    <p>Name 姓名: </p>
                    <p>Date 日期: </p>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <!-- authorized signature modal -->
    <b-modal
      id="authorized-sign-modal"
      ref="authorized-sign-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Authorized Signature"
      @ok="closeAuthorizedSignModal"
      @cancel="closeAuthorizedSignModal"
    >
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          @click="clearAuthorizedSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveAuthorizedSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ authorized signature modal -->
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BImg, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BImg,
    BFormGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    digitalForm: {
      type: Object,
      required: true,
    },
    topSpacing: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      gender: [],
      genderOptions: [
        { text: 'Male 男', value: 'Male 男' },
        { text: 'Female 女', value: 'Female 女' },
      ],
      need: [],
      needOptions: [
        { text: 'As-Need', value: 'as-need' },
        { text: 'Pre-Needed', value: 'pre-needed' },
      ],
      authorizedSign: false,
      authorizedSignSrc: '',
      options: {
        penColor: '#000000',
        onBegin: () => { this.$refs.signaturePad.resizeCanvas() },
      },
    }
  },
  methods: {
    closeAuthorizedSignModal() {
      this.$root.$emit('bv::hide::modal', 'authorized-sign-modal', '')
    },
    clearAuthorizedSign() {
      this.$refs.signaturePad.clearSignature()
    },
    saveAuthorizedSign() {
      const { data } = this.$refs.signaturePad.saveSignature()
      this.authorizedSignSrc = data
      if (data) {
        this.$root.$emit('bv::hide::modal', 'authorized-sign-modal', '')
        this.authorizedSign = true
      }
    },
  },
}
</script>
