<template>

  <div>
    <b-row
      class="content-header"
    >
      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="7"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="custom-header-title float-left pr-1 mb-0">
              {{ digitalForm.name }}
            </h2>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-row
      class="content-header v-sticky-sidebar-container service_request_task mx-3"
      style="background: #E0E8F2; border-radius: 6px;"
    >
      <b-col
        class="content-header-right d-md-block df-padding"
        md="12"
        cols="12"
      >
        <b-card
          no-body
          class="mb-0"
        >
          <div
            class="ps-main-content-wrapper"
            style="background: #fff;"
          >
            <b-card class="m-0">
              <b-card-text>
                <div class="orn-form-header d-flex justify-content-between mb-75">
                  <div class="d-form-logo_section" />
                  <div class="orm-form-form_no_section text-right">
                    <h5>
                      {{ digitalForm.formNumber }}
                    </h5>
                    <h5>
                      {{ digitalForm.revision }}
                    </h5>
                  </div>
                </div>
                <h3 class="mb-2">
                  SERVICE FORM 礼仪服务预约表格
                </h3>
                <b-table-simple
                  borderless
                  responsive
                  class="particulars_of_deceased d-none d-md-block ps-simple-table"
                >
                  <b-tbody>
                    <b-tr>
                      <b-th colspan="2">
                        Applicant Details
                      </b-th>
                      <b-th colspan="2">
                        Particulars of Deceased
                      </b-th>
                    </b-tr>
                    <b-tr>
                      <b-td>
                        Applicant 报名者:
                      </b-td>
                      <b-td><span class="text-primary">Jeffrey Lam</span></b-td>
                      <b-td>
                        Niche No. 福位编号:
                      </b-td>
                      <b-td><span class="text-primary">A-A5A-DA-07-198</span></b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>
                        Contact No. 联络号码:
                      </b-td>
                      <b-td><span class="text-primary">(65) 9988 7560</span></b-td>
                      <b-td>
                        Pedestal No. 牌位编号:
                      </b-td>
                      <b-td><span class="text-primary">-</span></b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>
                        Email 电邮:
                      </b-td>
                      <b-td><span class="text-primary">jlam123@gmail.com</span></b-td>
                      <b-td>
                        Anling Lot No. 安灵编号:
                      </b-td>
                      <b-td><span class="text-primary">-</span></b-td>
                    </b-tr>
                    <b-tr>
                      <b-td />
                      <b-td />
                      <b-td>
                        Name of Deceased 先人名讳:
                      </b-td>
                      <b-td><span class="text-primary">李小芬</span></b-td>
                    </b-tr>
                    <b-tr>
                      <b-td />
                      <b-td />
                      <b-td>
                        Departed Date 逝世日期:
                      </b-td>
                      <b-td><span class="text-primary">23/08/2022</span></b-td>
                    </b-tr>
                    <b-tr>
                      <b-td />
                      <b-td />
                      <b-td>
                        Age 年龄:
                      </b-td>
                      <b-td><span class="text-primary">97</span></b-td>
                    </b-tr>
                    <b-tr>
                      <b-td />
                      <b-td />
                      <b-td>
                        Gender 性别:
                      </b-td>
                      <b-td><span class="text-primary">Female</span></b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>

                <b-table-simple
                  borderless
                  responsive
                  class="d-block d-md-none ps-simple-table"
                >
                  <b-tbody>
                    <b-tr>
                      <b-th>
                        Applicant Details
                      </b-th>
                      <b-th />
                    </b-tr>
                    <b-tr>
                      <b-td>
                        Applicant 报名者:
                      </b-td>
                      <b-td><span class="text-primary">Jeffrey Lam</span></b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>
                        Contact No. 联络号码:
                      </b-td>
                      <b-td><span class="text-primary">(65) 9988 7560</span></b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>
                        Email 电邮:
                      </b-td>
                      <b-td><span class="text-primary">jlam123@gmail.com</span></b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>
                        Particulars of Deceased
                      </b-th>
                      <b-th />
                    </b-tr>
                    <b-tr>
                      <b-td>
                        Niche No. 福位编号:
                      </b-td>
                      <b-td><span class="text-primary">A-A5A-DA-07-198</span></b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>
                        Pedestal No. 牌位编号:
                      </b-td>
                      <b-td><span class="text-primary">-</span></b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>
                        Anling Lot No. 安灵编号:
                      </b-td>
                      <b-td><span class="text-primary">-</span></b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>
                        Name of Deceased 先人名讳:
                      </b-td>
                      <b-td><span class="text-primary">李小芬</span></b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>
                        Departed Date 逝世日期:
                      </b-td>
                      <b-td><span class="text-primary">23/08/2022</span></b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>
                        Age 年龄:
                      </b-td>
                      <b-td><span class="text-primary">97</span></b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>
                        Gender 性别:
                      </b-td>
                      <b-td><span class="text-primary">Female</span></b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>

                <div class="ps-service-type-block">
                  <h4 class="ps-service-type-title">
                    Service Type & Descriptions 服务类别和项目说明
                  </h4>
                  <h6 class="ps-service-type-name">
                    Niche 骨灰福位
                  </h6>
                  <div class="ps-service-des-content-wrapper">
                    <div class="ps-service-des-block">
                      <div class="ps-service-des-checkbox-block">
                        <b-form-checkbox
                          v-model="serviceDesCheckbox"
                          value="bone picking"
                          checked
                        >
                          <p>
                            Bone Picking 拾金 / 捡骨
                          </p>
                        </b-form-checkbox>
                      </div>
                      <div class="ps-service-des-content-block">
                        <b-table-simple
                          borderless
                          responsive
                          class="ps-simple-table"
                        >
                          <b-tbody>
                            <b-tr>
                              <b-td>
                                Location 地点:
                              </b-td>
                              <b-td><span class="text-primary">Mandai</span></b-td>
                            </b-tr>
                            <b-tr>
                              <b-td>
                                Service Date and Time 预定服务日期与时间:
                              </b-td>
                              <b-td><span class="text-primary">28/04/2023, 09:30am</span></b-td>
                            </b-tr>
                            <b-tr>
                              <b-td>
                                Related File No. 相关文件号码:
                              </b-td>
                              <b-td><span class="text-primary">A456789DE</span></b-td>
                            </b-tr>
                            <b-tr class="border-bottom">
                              <b-td>
                                Remark 备注:
                              </b-td>
                              <b-td><span class="text-primary">We have confirmed the date with client, so please keep it in mind and make arrangement accordingly.</span></b-td>
                            </b-tr>
                            <b-tr>
                              <b-td>
                                Service Location  拜祭地点:
                              </b-td>
                              <b-td><span class="text-primary">Outside Blk A A栋外面</span></b-td>
                            </b-tr>
                            <b-tr>
                              <b-td>
                                Nirvana Chanting Service (One Resident Monk) 山庄师父诵经服务 (1位师父)
                              </b-td>
                              <b-td><span class="text-primary">Ksitigarbha Sutra 地藏经 ($568)</span></b-td>
                            </b-tr>
                            <b-tr>
                              <b-td>
                                Offerings 供奉:
                              </b-td>
                              <b-td>
                                <span class="text-primary">Nirvana Offering:  6 vegetarians, Fruits & Cake 富贵供品: 六斋碗与供果发糕 S$68 x <span class="text-success">1</span></span>
                                <br>
                                <span class="text-primary">Premium Offering Set: Vegetarian Meat, Fish & Chicken & 3 ve getarians, Fruits & Cake 吉祥供品: 三牲三斋碗与供果发糕 S$88 x <span class="text-success">1</span></span>
                              </b-td>
                            </b-tr>
                            <b-tr>
                              <b-td>
                                Incense Paper 纸供:
                              </b-td>
                              <b-td><span class="text-primary">Heavenly Bag 天堂礼包 S$48 x <span class="text-success">2</span></span></b-td>
                            </b-tr>
                            <b-tr>
                              <b-td>
                                Rental of Premises 场地出租:
                              </b-td>
                              <b-td><span class="text-primary">4–7/Hours 小时（$388）</span></b-td>
                            </b-tr>
                            <b-tr>
                              <b-td>
                                No of external monks engaged 自聘师傅:
                              </b-td>
                              <b-td><span class="text-primary">x <span class="text-success">2</span></span></b-td>
                            </b-tr>
                            <b-tr>
                              <b-td>
                                No of guest 参与亲友:
                              </b-td>
                              <b-td><span class="text-primary">x <span class="text-success">38</span></span></b-td>
                            </b-tr>
                            <b-tr>
                              <b-td>
                                Chanting duration 预计诵经时间:
                              </b-td>
                              <b-td><span class="text-success">10 hours</span></b-td>
                            </b-tr>
                            <b-tr>
                              <b-td>
                                Other Requests 其他需求:
                              </b-td>
                              <b-td><span class="text-success">Heavenly Bag is FOC</span></b-td>
                            </b-tr>
                            <b-tr>
                              <b-td>
                                Age
                              </b-td>
                              <b-td><span class="text-success">97</span></b-td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                      </div>
                    </div>
                    <div class="ps-service-des-bg-block">
                      <div class="ps-service-des-checkbox-block">
                        <b-form-checkbox
                          v-model="serviceDesCheckbox"
                          value="bone picking"
                        >
                          <p>
                            Paste Longevity Sticker 长生
                          </p>
                        </b-form-checkbox>
                      </div>
                      <div class="ps-service-des-content-block">
                        <b-table-simple
                          borderless
                          responsive
                          class="ps-simple-table"
                        >
                          <b-tbody>
                            <b-tr class="border-bottom">
                              <b-td>
                                Location 地点:
                              </b-td>
                              <b-td><span class="text-primary">Mandai</span></b-td>
                            </b-tr>
                            <b-tr>
                              <b-td>
                                福
                              </b-td>
                              <b-td><span class="text-primary">x</span> <span class="text-success"> 2 (One for male and one for female)</span></b-td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                      </div>
                    </div>
                    <div class="border-bottom" />
                    <b-row class="m-0">
                      <b-col
                        lg="4"
                        class="border-right p-0"
                      >
                        <div class="ps-notice-left-section">
                          <b-row class="m-0">
                            <b-col
                              lg="12"
                              class="p-0"
                            >
                              <p>Agent Name 代理姓名:</p>
                              <p class="ps-notice-field-value">
                                陈国明
                              </p>
                            </b-col>
                            <b-col
                              lg="12"
                              class="p-0"
                            >
                              <p>Agent Code 代理编号:</p>
                              <p class="ps-notice-field-value">
                                -
                              </p>
                            </b-col>
                            <b-col
                              lg="12"
                              class="p-0"
                            >
                              <p>Agent Mobile Number 代理联络号码:</p>
                              <p class="ps-notice-field-value">
                                (65) 98512838
                              </p>
                            </b-col>
                          </b-row>
                        </div>
                      </b-col>
                      <b-col lg="8 p-0">
                        <div class="ps-notice-right-section">
                          <h5>Notice 备注</h5>
                          <ul>
                            <li>
                              Payment and Reservation is to be done 3 days in advance.
                              <br>
                              <span>预购是指三天前完成订货与缴付金额。</span>
                            </li>
                            <li>
                              Rental of premises is a must for chanting duration more than an hour.
                              <br>
                              <span>诵经时间一小时以上必需租用场地</span>
                            </li>
                            <li>
                              Rental of burning cage is required if there is a large amount of paper offerings. (eg. 8’ x 16’ of paper house or 12 offering chests and above.)
                              <br>
                              <span>若焚烧大量纸钱，衣箱（12个衣箱或超过）或纸屋（限制纸屋面积8尺 x 16尺）必需提出申请租用化宝架。</span>
                            </li>
                            <li>
                              Cheque shall be made payable to <b>“Mount Prajna Ltd”</b>
                              <br>
                              <span>支票上请注明交于</span>
                              <b>“Mount Prajna Ltd”</b>
                            </li>
                            <li>
                              All prices are inclusive GST.
                              <br>
                              <span>以上价格已含消费税。</span>
                            </li>
                          </ul>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>

                <div class="authorized-signature ps-authorized-signature mt-3">
                  <b-row>
                    <b-col md="6">
                      <p>
                        Purchaser / Authorized Representative Signatory & Date
                        <br>
                        <span>购买者/受委托领瓮人签名和日期</span>
                      </p>
                      <div
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="form-signature-box"
                        @click="openCustomerSignModal"
                      >
                        <div class="form-signature-content">
                          <span
                            v-if="!isSignature"
                            class="form-signature-text"
                          >
                            Sign here
                          </span>
                          <b-img
                            v-else
                            :src="customerSignature"
                            class="p-2 signature-image"
                            alt="authorized-sign"
                          />
                        </div>
                      </div>
                      <div class="signature-date-block ps-signature-date-block">
                        <p>Name 姓名: Jeffrey Lam</p>
                        <p>Date 日期: </p>
                      </div>
                    </b-col>
                  </b-row>
                </div>

                <div class="border mt-4 ps-bottom-box">
                  <h5 class="border-bottom text-center text-uppercase">
                    For office use 公务用栏
                  </h5>
                  <b-row>
                    <b-col
                      md="6"
                      class="px-2 py-2 ps-authorized-signature"
                    >
                      <p>Verified By 确认人员</p>
                      <div
                        class="form-signature-box bg-transparent"
                      >
                        <div class="form-signature-content">
                          <span
                            class="form-signature-text"
                          />
                        </div>
                      </div>
                      <div class="signature-date-block ps-signature-date-block">
                        <p>Name 姓名: </p>
                        <p>Date 日期: </p>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-card-text>
            </b-card>
            <div
              v-if="!isMobile()"
              class="ps-tooltip-card"
            >
              <b-card>
                <b-card-header class="bg-warning">
                  <slot name="header">
                    <p>Signature Required</p>
                  </slot>
                </b-card-header>
                <b-card-text>
                  <p>Signature is required to proceed.</p>
                  <p>需要签名方可继续。</p>
                  <b-button
                    class="ps-tooltip-button"
                  >
                    Bring me over
                  </b-button>
                </b-card-text>
              </b-card>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="customer-sign-modal"
      ref="customer-sign-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Customer Signature"
      @ok="closeCustomerSignModal"
      @cancel="closeCustomerSignModal"
    >
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          @click="clear()"
        >
          Clear
        </b-button>
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="save()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ customer signature modal -->

    <b-modal
      id="submit-sign-form-modal"
      size="lg"
      title=""
      centered
      no-stacking
      hide-footer
      header-class="front-modal"
      modal-class="sign-modal-adjust"
      style="max-width: 647px;"
    >
      <h3>Submit this signed form?</h3>
      <p>
        If the information in <strong>Service Form</strong> is accurate, submitting this form will be review by Duty Officer. A PDF copy to be sent out to customer after the reviewed.
      </p>
      <p class="mt-1">
        Please confirm the PDF will be send via:
      </p>
      <div class="mt-1">
        <b-button
          :variant="!smsOnly ? 'primary' : 'outline-primary'"
          class="sms-or-email__btn-border"
          @click="updateSmsOnly"
        >
          Email only
        </b-button>
        <b-button
          :variant="smsOnly ? 'primary' : 'outline-primary'"
          class="sms-or-email__btn-border"
          @click="updateSmsOnly"
        >
          SMS only
        </b-button>
        <div class="border">
          <b-row class="p-1">
            <b-col
              v-if="smsOnly"
              md="12"
            >
              <strong>Mobile Number</strong>
              <b-form-input class="mt-1" />
            </b-col>
            <b-col
              v-else
              md="12"
            >
              <strong>Email</strong>
              <b-form-input class="mt-1" />
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="mt-1 mb-2 text-center">
        <small>
          Note: This is a password protected document.
        </small>
        <br>
        <small>
          The password format is customer’s mobile number (XXXXXXXX).
        </small>
      </div>
      <div class="float-right">
        <b-button
          variant="link"
          class="mr-2 my-auto"
          @click="$bvModal.hide('submit-sign-form-modal')"
        >
          No, back to editing
        </b-button>
        <b-button
          variant="success"
        >
          <feather-icon
            icon="ArrowRightIcon"
            class="mr-50"
            size="16"
          />
          Yes, Proceed
        </b-button>
      </div>
      <div class="clear" />
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText, BFormInput, BButton, BFormCheckbox, BModal, BImg, BCardHeader, BTableSimple, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormInput,
    BButton,
    BFormCheckbox,
    BModal,
    BImg,
    BCardHeader,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
  },
  directives: {
    Ripple,
  },
  props: {
    digitalForm: {
      type: Object,
      required: true,
    },
    topSpacing: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      smsOnly: true,
      limitPosition: 195,
      scrolled: false,
      lastPosition: 0,
      bottomActionBlock: 0,
      phase: 'previewSignPhase',
      activity: {},
      pack: {},
      timeSlots: [],
      enabledDates: [],
      start: '',
      startValidation: false,
      startError: 'Valid date is required',
      startTime: '',
      startTimeValidation: false,
      startTimeError: 'Valid time is required',
      customerName: '',
      customerNameValidation: false,
      customerNameError: 'Valid name is required',
      customerContact: '',
      customerContactValidation: false,
      customerContactError: 'Valid 8 digit mobile number is required',
      customerEmail: '',
      customerEmailValidation: false,
      customerEmailError: 'Valid email is required',
      agentContactError: 'Valid mobile is required',
      agentContactValidation: false,
      deceasedGender: '',
      deceasedGenderError: 'Valid name is required',
      deceasedGenderValidation: false,
      serviceDescription: '',
      serviceDesCheckbox: ['bone picking'],
      firstAgree: '',
      serviceType: '',
      serviceTypeDetail: '',
      remarks: '',
      title: '',
      agentName: '',
      agentCode: '',
      agentContact: '',
      otpSentToPhone: '',
      enteredOTP: '',
      enteredOTPError: 'Valid otp is required',
      enteredOTPValidation: false,
      customFields: [],
      event: {},
      timeSlotDuration: '',
      secondAgree: '',
      existingBookingWarning: false,
      pageNotFound: false,
      enableNextPhaseCustomer: false,
      enableNextPhaseCustomFields: false,
      timeCountDown: 0,
      hasAnotherBooking: false,
      /* eslint-disable global-require */
      logoImg: require('@/assets/images/frontend/logo-nirvana-memorial-garden.86fe2ff5.svg'),
      modalIcon: require('@/assets/images/frontend/modal_icon.svg'),
      isPhoneHidden: false,
      isEmailHidden: true,
      customFieldsLength: true,
      flatPickrConfig: {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', enable: [], disable: [], minDate: 'today', disableMobile: true,
      },
      flatPickrConfigWithoutTime: {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', disableMobile: true,
      },
      flatPickrConfigTime: {
        wrap: true, noCalendar: true, enableTime: true, dateFormat: 'H:i', disableMobile: true,
      },
      requiredOptions: [
        { text: 'Yes', value: 'yes' },
        { text: 'No', value: 'no' },
      ],
      genderOptions: [
        { text: 'Male', value: 'male' },
        { text: 'Female', value: 'female' },
      ],
      serviceOptions: [
        { text: 'Niche 骨灰福位', value: 'niche' },
        { text: 'Pedestal 神主牌位', value: 'pedestal' },
        { text: 'Ceremony 仪式', value: 'ceremony' },
      ],
      serviceDetailOptions: [
        { text: 'Interment of Urn 晋塔', value: 'interment of urn' },
        { text: 'Bone Picking 拾金/捡骨', value: 'bone picking' },
        { text: 'Urn Replacement 换瓮', value: 'urn replacement' },
        { text: 'Urn Storage 添福寿', value: 'urn storage' },
        { text: 'Paste Longevity Sticker 长生', value: 'paste longevity sticker' },
      ],
      serviceLocationOptions: [
        { text: 'Prayer Room 祭祀厅', value: 'prayer room' },
        { text: 'Anling Room 安灵房', value: 'anling room' },
        { text: 'Outside Blk A A栋外面', value: 'outside blk' },
        { text: 'Blk C Lvl 3 Training Hall Area C 栋3楼培训厅', value: 'blk c lvl' },
        { text: 'Blk C Lvl 3 Training Hall Area C栋3楼升天仪式房', value: 'blk c lvl 3' },
        { text: 'Tentage 后山', value: 'paste' },
      ],
      chantingServiceOptions: [
        { text: 'Standard Chanting 标准诵经 ($168)', value: 'standard' },
        { text: 'Ksitigarbha Sutra 地藏经 ($568)', value: 'ksitigarbha' },
      ],
      rentalPremisesOptions: [
        { text: '1–4/Hours 小时（$238）', value: '1-4/hours' },
        { text: '4–7/Hours 小时（$388）', value: '4-7/hours' },
      ],
      isSignature: false,
      customerSignature: '',
      options: {
        penColor: '#000000',
        onBegin: () => { this.$refs.signaturePad.resizeCanvas() },
      },
      required,
    }
  },
  beforeMount() {
    this.$http
      .get('operation/service-forms')
      .then(() => {
        // console.log(response.data)
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || '',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {
    closeCustomerSignModal() {
      this.$root.$emit('bv::hide::modal', 'customer-sign-modal', '')
    },
    openCustomerSignModal() {
      this.$root.$emit('bv::show::modal', 'customer-sign-modal', '')
    },
    clear() {
      this.$refs.signaturePad.clearSignature()
    },
    save() {
      const { data } = this.$refs.signaturePad.saveSignature()
      this.customerSignature = data
      if (data) {
        this.$root.$emit('bv::hide::modal', 'customer-sign-modal', '')
        this.isSignature = true
      }
    },
    updateSmsOnly() {
      this.smsOnly = !this.smsOnly
    },
  },
}
</script>

<style lang="scss" scoped>
  .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
    border-right-width: 1px !important;
  }
  .inlineElement {
    display: flex;
  }
  .clickableText {
    cursor: pointer;
    color: #104D9D;
  }
  ul.nav.wrap-border.save-nav {
    position: inherit;
    left: 0;
}
.service-request-card {
    margin: 40px 0 40px !important;
}
.sd-step-wrapper {
    padding: 40px 0;
}
.sd-step-title{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #000000;
}
.sd-step-opacity {
   opacity: 0.3;
}
.sd-service-single-block{
   background: #E0E8F2;
   border-radius: 6px;
   padding: 20px;
   margin: 10px 0;
}
.sd-option-button{
   display: block;
}
.sd-option-button .btn-outline-primary:hover:not(.disabled):not(:disabled) {
    background-color: #104D9D;
    color: #FFFFFF;
}
.sd-option-button .btn-outline-primary:not(:disabled):not(.disabled).active, .sd-option-button .btn-outline-primary:not(:disabled):not(.disabled):focus {
     background-color: #104D9D;
     color: #FFFFFF;
}

.sd-service-description-title{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.sd-single-service-description{
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    background: #F5F8FB;
    border-radius: 6px;
    margin: 10px 0;
    p{
       margin-bottom: 0;
       margin: auto 0;
    }
   .increment-decrement-block{
     margin: 0;
   }
}
.ps-avatar-bg{
   background: rgba(255, 255, 255, 0.3);
}

.ps-cd-header-text-section{
     margin-left: 20px;
     font-family: 'Montserrat';
     font-style: normal;
     font-weight: 500;
     margin-top: -5px;
     p{
       margin-bottom: 0;
       color: #FFFFFF;
       font-size: 12px;
       line-height: 23px;
       letter-spacing: 0.6px;
       text-transform: uppercase;
     }
     h5{
       margin-bottom: 0;
       font-size: 18px;
       line-height: 22px;
       color: #FFFFFF;
     }
}
.ps-main-content-wrapper{
     background: #9FB8D8;
     padding: 15px;
     position: relative;
}
.ps-tooltip-card{
    position: absolute;
    left: -33%;
    bottom: 72.7%;
}
.ps-tooltip-card .card .card-body{
   padding: 0 !important;
}

.ps-tooltip-card .card .card-body .card-text{
   padding: 17px;
   p{
    margin-bottom: 0;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
   }
}
.ps-tooltip-card .card .card-body .card-text .ps-tooltip-button{
      background: rgba(255, 159, 67, 0.12) !important;
      border: none !important;
      color: #FF9F43 !important;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      text-align: center;
      letter-spacing: 0.366667px;
      padding: 8px 19px;
      margin-top: 20px !important;
   }
.ps-tooltip-card .card .card-body .card-header{
   padding: 7px 16px;
   p{
     margin-bottom: 0;
     font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #FFFFFF;
   }
}
.ps-main-content-wrapper .card .card-body{
    padding: 20px;
}
.ps-simple-table .table th, .ps-simple-table .table td {
    padding: 3px 0 !important;
}
.ps-service-type-block{
      border-width: 0px 1px 1px 1px;
      border-style: solid;
      border-color: #CBCBCB;
      .ps-service-type-title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
        background: #4B4B4B;
        padding: 10px 20px;
        margin: 0;
    }
    .ps-service-type-name{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        background: #D2DCEA;
        padding: 6px 20px;
    }
}

.ps-service-des-block{
   padding: 10px 20px;
}
.ps-service-des-checkbox-block{
    p{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
       font-size: 14px;
       line-height: 20px;
       color: #000000;
       margin: 0;
    }
  }

.ps-service-des-content-block{
   padding: 0px 0px 0px 28px;
}

.ps-service-des-bg-block{
    background: rgba(186, 191, 199, 0.12);
    padding: 10px 20px;
    margin-bottom: 10px;
}
.ps-notice-left-section{
     padding: 20px;
     p{
       font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
      margin-bottom: 15px;
     }
     .ps-notice-field-value{
        color: #104D9D !important;
        border-bottom: 1px solid #000000;
     }
}
.ps-notice-right-section{
     padding: 10px;
     font-family: 'Montserrat';
     font-style: normal;
     h5{
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      color: #000000;
     }
     ul{
       padding-left: 20px;
       list-style: disc;
     }
     ul > li{
          color: #000000;
          font-size: 14px;
          line-height: 18px;
          padding: 3px 0;
     }

}
.ps-authorized-signature{
    font-family: 'Montserrat';
    font-style: normal;
   p{
      font-weight: 500;
      font-size: 12px;
      line-height: 17px;
      margin-bottom: 10px;
      color: #000000;
   }
}
 .ps-authorized-signature .ps-signature-date-block p{
      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
      margin-bottom: 0;
      color: #000000;
 }
 .ps-bottom-box{
    h5{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
      padding: 8px;
    }
 }
</style>
