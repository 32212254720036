<template>
  <div>
    <b-row
      class="content-header"
    >
      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="7"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="custom-header-title float-left pr-1 mb-0">
              {{ digitalForm.name }}
            </h2>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="orn-form-wrapper m-4">
        <div class="orn-form-header mb-75 d-flex justify-content-between">
          <div class="d-form-logo_section c-feedback-logo-adjustment" />
          <div class="d-form-company-section-tag">
            <h2 class="c-title-top">
              富貴山莊
            </h2>
            <div class="d-flex">
              <h2 class="c-title-bottom">
                Mount Prajna Ltd.
              </h2>
              <div class="c-info-des">
                <p>
                  (Co. Reg. No. 200401183W)
                </p>
                <p>
                  (GST Group Reg. No. M90364395L)
                </p>
              </div>
            </div>
          </div>
          <div class="d-form-no_section text-right">
            <h5>
              {{ digitalForm.formNumber }}
            </h5>
            <h5>
              {{ digitalForm.revision }}
            </h5>
          </div>
        </div>
        <div class="urn-repository-order_section">
          <div class="b-form-section-title my-2">
            <h3>
              Customer’s comment form <span>客户意见表</span>
            </h3>
          </div>
          <p class="d-form-warning-text">
            We believe that the continuous and successful way to improve company’s quality service is to have feedback from our clients and members of the public.
          </p>
          <p class="d-form-warning-text">
            我们深信唯有不断的追求进步，才能提升我们的服务品质。请让我们得到您对本公司的意见如何？
          </p>
          <p class="d-form-warning-text">
            Kindly let us know your views and recommendation as to our service.
          </p>
        </div>
        <div class="urn-form-particular_section">
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label-cols="12"
                label-cols-md="4"
                label-cols-lg="1"
                label="Purchaser 购买者*:"
                label-for="input-default"
              >
                <div
                  class="empty-input__text"
                ><p
                  emptyDiv="true"
                  data-text="Enter"
                /></div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label-cols="12"
                label-cols-md="4"
                label-cols-lg="1"
                label="Next-of-Kin 购买者至亲*:"
                label-for="input-default"
              >
                <div
                  class="empty-input__text"
                ><p
                  emptyDiv="true"
                  data-text="Enter"
                /></div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label-cols="12"
                label-cols-md="4"
                label-cols-lg="1"
                label="Contact No. 联络电话*: "
                label-for="input-default"
              >
                <div
                  class="empty-input__text"
                ><p
                  emptyDiv="true"
                  data-text="Enter"
                /></div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label-cols="12"
                label-cols-md="4"
                label-cols-lg="1"
                label="Address 联络地址*: "
                label-for="input-default"
              >
                <div
                  class="empty-input__text"
                ><p
                  emptyDiv="true"
                  data-text="Enter"
                /></div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label-cols="12"
                label-cols-md="4"
                label-cols-lg="1"
                label="File No. 档案编号*: "
                label-for="input-default"
              >
                <div
                  class="empty-input__text"
                ><p
                  emptyDiv="true"
                  data-text="Enter"
                /></div>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="d-form-an-ling-block">
            <b-table-simple
              class="d-form-customer-feedback-table"
              borderless
              responsive
            >
              <b-tbody>
                <b-tr>
                  <b-th style="padding-left: 21px">
                    (A) Service 在服务态度方面
                  </b-th>
                  <b-th class="text-center">
                    Excellent 良好
                  </b-th>
                  <b-th class="text-center">
                    Good 好
                  </b-th>
                  <b-th class="text-center">
                    Bad 差
                  </b-th>
                </b-tr>
                <b-tr>
                  <b-td><span style="padding-right: 12px;">1</span>Staff’s Attitude 人员服务态度*</b-td>
                  <b-td class="customer-feedback-custom-td" />
                  <b-td class="customer-feedback-custom-td" />
                  <b-td class="customer-feedback-custom-td" />
                </b-tr>
                <b-tr>
                  <b-td><span style="padding-right: 12px;">2</span>Courtesy 礼貌*</b-td>
                  <b-td class="customer-feedback-custom-td" />
                  <b-td class="customer-feedback-custom-td" />
                  <b-td class="customer-feedback-custom-td" />
                </b-tr>
                <b-tr>
                  <b-td><span style="padding-right: 12px;">3</span>Effectiveness 效率*</b-td>
                  <b-td class="customer-feedback-custom-td" />
                  <b-td class="customer-feedback-custom-td" />
                  <b-td class="customer-feedback-custom-td" />
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
          <div class="d-form-an-ling-block mt-2">
            <h3 class="pl-2">(B) Is our company providing professional way of service?*
              <br>
              <span style="padding-left: 1.8rem;">是否具备殡葬的专业知识？</span>
            </h3>
            <div class="mt-1 pl-2">
              <b-form-checkbox-group style="padding-left: 1.8rem;">
                <b-form-checkbox
                  value="Yes 是"
                >
                  Yes 是
                </b-form-checkbox>
                <b-form-checkbox
                  value="No 否"
                >
                  No 否
                </b-form-checkbox>
                <b-form-checkbox
                  value="Need to improve 须多加强"
                >
                  Need to improve 须多加强
                </b-form-checkbox>
              </b-form-checkbox-group>
            </div>
          </div>
          <div class="d-form-an-ling-block mt-2">
            <h3 class="pl-2">(C) Suggestion or improvement*
              <br>
              <span style="padding-left: 1.8rem;">建议加强事项</span>
            </h3>
            <div class="mt-1 pl-2">
              <div
                class="empty-input__textarea"
              ><p
                emptyDiv="true"
                data-text="Enter"
              /></div>
            </div>
          </div>
          <div class="my-2">
            <p class="d-form-warning-text">
              Thank you for your feedback and we would like to take this opportunity to salute you for your kindly support. Please submit this questionnaire’s to our service department staff.
            </p>
            <p class="d-form-warning-text">
              感谢您拨出宝贵的时间填写此问卷, 富贵山庄衷心感谢您的支持与爱护。填毕此问卷后, 请将此问卷 交回给我们的服务人员。
            </p>
          </div>
          <div class="mt-5">
            <b-row>
              <b-col md="6">
                <div
                  class="d-form-company-contact-info"
                >
                  <h6>
                    Tel/Hotline 电话/热线:
                  </h6>
                  <p>(65) 6397 2272</p>
                </div>
                <div
                  class="d-form-company-contact-info"
                >
                  <h6>
                    Fax 传真:
                  </h6>
                  <p>(65) 6795 3070</p>
                </div>
                <div class="d-form-company-name">
                  <h3>Nirvana Memorial Garden Pte Ltd
                    <br>
                    <span>富贵山庄敬上</span>
                  </h3>
                </div>
              </b-col>
              <b-col md="6">
                <p class="d-form-signature-title">
                  Customer Signature 顾客签名
                </p>
                <div
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="form-signature-box"
                  @click="openCustomerSignModal"
                >
                  <div class="form-signature-content">
                    <span
                      v-if="!isSignature"
                      class="form-signature-text"
                    >
                      Sign here
                    </span>
                    <b-img
                      v-else
                      :src="customerSignature"
                      alt="authorized-sign"
                    />
                  </div>
                </div>
                <div class="signature-date-block">
                  <p>Name 姓名: </p>
                  <p>Date 日期: </p>
                </div>
              </b-col>
            </b-row>
            <b-row class="pt-1">
              <b-col md="6">
                <div class="d-form-company-address">
                  <p class="d-form-warning-text">
                    950 Old Choa Chua Kang Road, Singapore 699816
                  </p>
                  <h5>www.nirvana.com.sg</h5>
                </div>
              </b-col>
              <b-col md="6">
                <div class="d-flex justify-content-between">
                  <p class="d-form-warning-text">
                    Tel: (65) 63972272
                  </p>
                  <p class="d-form-warning-text">
                    Fax: (65) 63972252
                  </p>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-card>
    <!-- customer signature modal -->
    <b-modal
      id="customer-sign-modal"
      ref="customer-sign-modal"
      modal-class="status-update-modal"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Customer Signature"
      @ok="closeCustomerSignModal"
      @cancel="closeCustomerSignModal"
    >
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          @click="clear()"
        >
          Clear
        </b-button>
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="save()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ customer signature modal -->
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BTableSimple, BTbody, BTr, BTh, BTd, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormCheckboxGroup,
    BFormCheckbox,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BImg,
  },
  directives: {
    Ripple,
  },
  props: {
    digitalForm: {
      type: Object,
      required: true,
    },
    topSpacing: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      gender: [],
      genderOptions: [
        { text: 'Male 男', value: 'Male 男' },
        { text: 'Female 女', value: 'Female 女' },
      ],
      need: [],
      needOptions: [
        { text: 'As-Need', value: 'as-need' },
        { text: 'Pre-Needed', value: 'pre-needed' },
      ],
      isSignature: false,
      customerSignature: '',
      options: {
        penColor: '#000000',
        onBegin: () => { this.$refs.signaturePad.resizeCanvas() },
      },
    }
  },
  methods: {
    closeCustomerSignModal() {
      this.$root.$emit('bv::hide::modal', 'customer-sign-modal', '')
    },
    openCustomerSignModal() {
      this.$root.$emit('bv::show::modal', 'customer-sign-modal', '')
    },
    clear() {
      this.$refs.signaturePad.clearSignature()
    },
    save() {
      const { data } = this.$refs.signaturePad.saveSignature()
      this.customerSignature = data
      if (data) {
        this.$root.$emit('bv::hide::modal', 'customer-sign-modal', '')
        this.isSignature = true
      }
    },
  },
}
</script>
