<template>

  <div>
    <b-row
      class="content-header"
    >
      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="7"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="custom-header-title float-left pr-1 mb-0">
              Pedestal installation order 神主牌安位嘱咐书
            </h2>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="urn-form-wrapper m-4 ">
        <div class="orn-form-header mb-75 d-flex">
          <div class="d-form-logo_section logo-adjustment" />
          <div class="d-form-company-section-tag">
            <h2 class="c-title-top">
              富貴山莊
            </h2>
            <div class="d-flex">
              <h2 class="c-title-bottom">
                Mount Prajna Ltd.
              </h2>
              <div class="c-info-des">
                <p>
                  (Co. Reg. No. 200401183W)
                </p>
                <p>
                  (GST Group Reg. No. M90364395L)
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="urn-repository-order_section">
          <div class="b-form-section-title my-2">
            <h3>Pedestal installation order 神主牌安位嘱咐书</h3>
          </div>
          <b-row>
            <b-col md="6">
              <b-form-group
                label-cols="4"
                label-cols-lg="2"
                label-for="input-default"
              >
                <template #label>
                  <span>I as Purchaser*,</span>
                  <br>
                  <span>我</span>
                </template>
                <div
                  class="empty-input__text"
                ><p
                  emptyDiv="true"
                  data-text="Enter"
                /></div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label-cols="4"
                label-cols-lg="2"
                label-for="input-default"
              >
                <template #label>
                  <span>NRIC*</span>
                  <br>
                  <span>身份證號碼</span>
                </template>
                <div
                  class="empty-input__text"
                ><p
                  emptyDiv="true"
                  data-text="Enter"
                /></div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label-cols="4"
                label-cols-lg="2"
                label-for="input-default"
              >
                <template #label>
                  <span>I as Next of Kin*,</span>
                  <br>
                  <span>我</span>
                </template>
                <div
                  class="empty-input__text"
                ><p
                  emptyDiv="true"
                  data-text="Enter"
                /></div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label-cols="4"
                label-cols-lg="2"
                label-for="input-default"
              >
                <template #label>
                  <span>NRIC*</span>
                  <br>
                  <span>身份證號碼</span>
                </template>
                <div
                  class="empty-input__text"
                ><p
                  emptyDiv="true"
                  data-text="Enter"
                /></div>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="d-form-warning-text-block">
            <p class="d-form-warning-text">
              Being the purchaser / next-of-kin of purchaser do hereby order that the pedestal described below be installed:
            </p>
            <p class="d-form-warning-text">
              本人为购者 / 本人为购者至亲，仅此嘱咐，以下所列明的神主牌位已可安位。
            </p>
          </div>
          <b-row>
            <b-col
              cols="12"
              class="mt-2"
            >
              <b-form-group
                label-cols="4"
                label-cols-lg="1"
                label-for="input-default"
              >
                <template #label>
                  <span>File No.*</span>
                  <br>
                  <span>文件号码</span>
                </template>
                <div
                  class="empty-input__text"
                ><p
                  emptyDiv="true"
                  data-text="Enter"
                /></div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label-cols="4"
                label-cols-lg="1"
                label-for="input-default"
              >
                <template #label>
                  <span>Pedestal No.*:</span>
                  <br>
                  <span>神主牌位</span>
                </template>
                <div
                  class="empty-input__text"
                ><p
                  emptyDiv="true"
                  data-text="Enter"
                /></div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label-cols="4"
                label-cols-lg="1"
                label-for="input-default"
              >
                <template #label>
                  <span>Name of Beneficiary:</span>
                  <br>
                  <span>受供奉者姓氏</span>
                </template>
                <div
                  class="d-form-input-field-wrapper"
                >
                  <div
                    class="d-form-input-wrapper"
                  >
                    <p class="m-auto">
                      1)
                    </p>
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </div>
                  <div
                    class="d-form-input-wrapper"
                  >
                    <p class="m-auto">
                      2)
                    </p>
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </div>
                  <div
                    class="d-form-input-wrapper"
                  >
                    <p class="m-auto">
                      3)
                    </p>
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </div>
                </div>
                <div
                  class="d-form-input-field-wrapper mt-1"
                >
                  <div
                    class="d-form-input-wrapper"
                  >
                    <p class="m-auto">
                      4)
                    </p>
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </div>
                  <div
                    class="d-form-input-wrapper"
                  >
                    <p class="m-auto">
                      5)
                    </p>
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </div>
                  <div
                    class="d-form-input-wrapper"
                  >
                    <p class="m-auto">
                      6)
                    </p>
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label-cols="4"
                label-cols-lg="1"
                label-for="input-default"
              >
                <template #label>
                  <span>Relationship:</span>
                  <br>
                  <span>关系</span>
                </template>
                <div
                  class="d-form-input-field-wrapper"
                >
                  <div
                    class="d-form-input-wrapper"
                  >
                    <p class="m-auto">
                      1)
                    </p>
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </div>
                  <div
                    class="d-form-input-wrapper"
                  >
                    <p class="m-auto">
                      2)
                    </p>
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </div>
                  <div
                    class="d-form-input-wrapper"
                  >
                    <p class="m-auto">
                      3)
                    </p>
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </div>
                </div>
                <div
                  class="d-form-input-field-wrapper  mt-1"
                >
                  <div
                    class="d-form-input-wrapper"
                  >
                    <p class="m-auto">
                      4)
                    </p>
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </div>
                  <div
                    class="d-form-input-wrapper"
                  >
                    <p class="m-auto">
                      5)
                    </p>
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </div>
                  <div
                    class="d-form-input-wrapper"
                  >
                    <p class="m-auto">
                      6)
                    </p>
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col md="6">
              <p class="d-form-signature-title">Purchaser’s / Next of Kin’s Signatory & Date
                <br>
                <span>购者或至亲签名</span>
              </p>
              <div
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-modal.purchaser-sign-modal
                class="form-signature-box"
              >
                <div class="form-signature-content">
                  <span
                    v-if="!isPurchaserSign"
                    class="form-signature-text"
                  >
                    Sign here
                  </span>
                  <b-img
                    v-else
                    :src="purchaserSign"
                    alt="sign"
                  />
                </div>
              </div>
              <div class="signature-date-block">
                <p>Name 姓名: </p>
                <p>Date 日期: </p>
              </div>
            </b-col>
          </b-row>
          <div class="d-form-border mt-4">
            <h5 class="d-form-office-use-title">
              For office use 公务用栏
            </h5>
            <div
              class="d-form-border-bottom"
            >
              <b-row>
                <b-col
                  md="8"
                  class="pr-0"
                >
                  <div class="padding-20">
                    <b-form-group
                      label-for="input-default"
                      label-cols-md="2"
                      label-cols="12"
                      class="mb-0"
                    >
                      <template #label>
                        <span>Date of Installation:</span>
                        <br>
                        <span>安装日期</span>
                      </template>
                      <div
                        class="empty-input__text"
                      ><p
                        emptyDiv="true"
                        data-text="Enter"
                      /></div>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col
                  md="4"
                  class="pl-0"
                >
                  <div class="padding-20">
                    <b-form-group
                      label-for="input-default"
                      label-cols-md="2"
                      label-cols="12"
                    >
                      <template #label>
                        <span>Time:</span>
                        <br>
                        <span>时间</span>
                      </template>
                      <div
                        class="empty-input__text"
                      ><p
                        emptyDiv="true"
                        data-text="Enter"
                      /></div>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
            </div>
            <b-row>
              <b-col
                md="6"
                class="d-form-border-right pr-0"
              >
                <div class="padding-20">
                  <p class="d-form-signature-title">
                    Check by 检查人员:
                  </p>
                  <div
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.check-by-sign-modal
                    class="form-signature-box"
                  >
                    <div class="form-signature-content">
                      <span
                        v-if="!checkBySign"
                        class="form-signature-text"
                      >
                        Sign here
                      </span>
                      <b-img
                        v-else
                        :src="checkBySignSrc"
                        alt="authorized-sign"
                      />
                    </div>
                  </div>
                  <div class="signature-date-block">
                    <p>Name 姓名: </p>
                    <p>Date 日期: </p>
                  </div>
                </div>
              </b-col>
              <b-col
                md="6"
                class="pl-0"
              >
                <div class="padding-20">
                  <p class="d-form-signature-title">
                    Person in Charge 服务人员:
                  </p>
                  <div
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.person-in-charge-sign-modal
                    class="form-signature-box"
                  >
                    <div class="form-signature-content">
                      <span
                        v-if="!personInChargeSign"
                        class="form-signature-text"
                      >
                        Sign here
                      </span>
                      <b-img
                        v-else
                        :src="personInChargeSignSrc"
                        alt="authorized-sign"
                      />
                    </div>
                  </div>
                  <div class="signature-date-block">
                    <p>Name 姓名: </p>
                    <p>Date 日期: </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-card>
    <!-- purchaser signature modal -->
    <b-modal
      id="purchaser-sign-modal"
      ref="purchaser-sign-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Purchaser's Signature"
      @ok="closePurchaserSignModal"
      @cancel="closePurchaserSignModal"
    >
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          @click="clearPurchaserSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="savePurchaserSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ purchaser signature modal -->
    <!-- person in charge signature modal -->
    <b-modal
      id="person-in-charge-sign-modal"
      ref="person-in-charge-sign-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Person in Charge Signature"
      @ok="closePersonInChargeSignModal"
      @cancel="closePersonInChargeSignModal"
    >
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          @click="clearPersonInChargeSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="savePersonInChargeSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ person in charge signature modal -->
    <!-- system updated signature modal -->
    <b-modal
      id="check-by-sign-modal"
      ref="check-by-sign-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Checked By Signature"
      @ok="closeCheckBySignModal"
      @cancel="closeCheckBySignModal"
    >
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          @click="clearCheckBySign()"
        >
          Clear
        </b-button>
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveCheckBySign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ system updated signature modal -->
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BButton, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BImg,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isPurchaserSign: false,
      purchaserSign: '',
      personInChargeSign: false,
      personInChargeSignSrc: '',
      checkBySign: false,
      checkBySignSrc: '',
      options: {
        penColor: '#000000',
        onBegin: () => { this.$refs.signaturePad.resizeCanvas() },
      },
    }
  },
  methods: {
    closePurchaserSignModal() {
      this.$root.$emit('bv::hide::modal', 'purchaser-sign-modal', '')
    },
    clearPurchaserSign() {
      this.$refs.signaturePad.clearSignature()
    },
    savePurchaserSign() {
      const { data } = this.$refs.signaturePad.saveSignature()
      this.purchaserSign = data
      if (data) {
        this.$root.$emit('bv::hide::modal', 'purchaser-sign-modal', '')
        this.isPurchaserSign = true
      }
    },
    closePersonInChargeSignModal() {
      this.$root.$emit('bv::hide::modal', 'person-in-charge-sign-modal', '')
    },
    clearPersonInChargeSign() {
      this.$refs.signaturePad.clearSignature()
    },
    savePersonInChargeSign() {
      const { data } = this.$refs.signaturePad.saveSignature()
      this.personInChargeSignSrc = data
      if (data) {
        this.$root.$emit('bv::hide::modal', 'person-in-charge-sign-modal', '')
        this.personInChargeSign = true
      }
    },
    closeCheckBySignModal() {
      this.$root.$emit('bv::hide::modal', 'check-by-sign-modal', '')
    },
    clearCheckBySign() {
      this.$refs.signaturePad.clearSignature()
    },
    saveCheckBySign() {
      const { data } = this.$refs.signaturePad.saveSignature()
      this.checkBySignSrc = data
      if (data) {
        this.$root.$emit('bv::hide::modal', 'check-by-sign-modal', '')
        this.checkBySign = true
      }
    },
  },
}
</script>
