<template>
  <div>
    <b-row
      class="content-header"
    >
      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="7"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="custom-header-title float-left pr-1 mb-0">
              {{ digitalForm.name }}
            </h2>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row
      class="content-header v-sticky-sidebar-container service_request_task mx-3"
      style="background: #E0E8F2; border-radius: 6px;"
    >
      <b-col
        class="content-header-right d-md-block df-padding"
        md="12"
        cols="12"
      >
        <!-- Table Container Card -->
        <b-card
          no-body
          class="mb-0"
        >
          <div class="urn-form-wrapper m-4 ">
            <div class="orn-form-header d-flex justify-content-between mb-75">
              <div class="d-form-logo_section" />
              <div class="d-form-no_section">
                <h5>
                  {{ digitalForm.formNumber }}
                </h5>
                <h5>
                  {{ digitalForm.revision }}
                </h5>
              </div>
            </div>
            <div class="urn-repository-order_section">
              <div class="form-section-title my-2">
                <h3
                  class="text-bold-black fs-16"
                  style="text-transform: uppercase;"
                >
                  Request for Urn Repository For storage purpose only 骨瓮置入福位声明
                </h3>
              </div>
              <div class="border p-2 my-2">
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-for="input-default"
                    >
                      <template #label>
                        <span class="">Niche No.*:</span>
                        <br>
                        <span>福位编号</span>
                      </template>
                      <div
                        class="empty-input__text"
                      ><p
                        emptyDiv="true"
                        data-text="Enter"
                      /></div>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-for="input-default"
                    >
                      <template #label>
                        <span class="">File No.*:</span>
                        <br>
                        <span>文件号码</span>
                      </template>
                      <div
                        class="empty-input__text"
                      ><p
                        emptyDiv="true"
                        data-text="Enter"
                      /></div>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="pb-0">
                  <b-col md="6">
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-for="input-default"
                    >
                      <template #label>
                        <span class="">Imported Urn Type*:</span>
                        <br>
                        <span>骨瓮款式</span>
                      </template>
                      <div
                        class="empty-input__text"
                      ><p
                        emptyDiv="true"
                        data-text="Enter"
                      /></div>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-for="input-default"
                    >
                      <template #label>
                        <span class="">Unit*:</span>
                        <br>
                        <span>数量</span>
                      </template>
                      <div
                        class="empty-input__text"
                      ><p
                        emptyDiv="true"
                        data-text="Enter"
                      /></div>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="3"
                    label-for="input-default"
                  >
                    <template #label>
                      <span class="">I as Purchaser/Authorized Representative*,</span>
                      <br>
                      <span>我, 购买者/购买者之委托人</span>
                    </template>
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="1"
                    label-for="input-default"
                  >
                    <template #label>
                      <span class="">of*</span>
                      <br>
                      <span>的</span>
                    </template>
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="2"
                    label-for="input-default"
                  >
                    <template #label>
                      <span class="">NRIC*:</span>
                      <br>
                      <span>身份证号码</span>
                    </template>
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="2"
                    label-for="input-default"
                  >
                    <template #label>
                      <span class="">Contact No.</span>
                      <br>
                      <span>联络号码</span>
                    </template>
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="2"
                    label-for="input-default"
                  >
                    <template #label>
                      <span class="">I as Next of Kin*,</span>
                      <br>
                      <span>我, 购买者之至亲</span>
                    </template>
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="1"
                    label-for="input-default"
                  >
                    <template #label>
                      <span class="">of*</span>
                      <br>
                      <span>的</span>
                    </template>
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="2"
                    label-for="input-default"
                  >
                    <template #label>
                      <span class="">NRIC*:</span>
                      <br>
                      <span>身份证号码</span>
                    </template>
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="2"
                    label-for="input-default"
                  >
                    <template #label>
                      <span class="">Contact No.</span>
                      <br>
                      <span>联络号码</span>
                    </template>
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="text-block">
                <p>is the purchaser / authorized representative / next of kin of the above niche seek for your consent to repose the aforesaid urn (“the Said Urn”) without containing cremated ask in the above mentioned Niche. </p>
                <p class="black-font weight-400">
                  本人乃新加坡富贵山庄福位购买者/购买者之至亲/购买者之委托人（本人身分证号，文件号码，骨瓮类别与骨瓮数量如附表），请协助将如数骨瓮置入所购福位。
                </p>
                <p>I shall indemnity you and keep you fully and competely indemnifield against all claims, liabilities demand actions, proceedings costs and expense that you may suffer arising from your acceptance of the Said Urn.</p>
              </div>
            </div>
            <div class="urn-form-terms-and-condition border p-1">
              <h3
                class="text-center fs-14 text-bold-black"
                style="text-transform: uppercase;"
              >
                Terms and conditions 规则与条件
              </h3>
              <ul class="form-text-block-list">
                <li>The said urn is reposed in the Said Niche at no extra costs.</li>
                <li>The Company is not responsible and liable for any loss/ damage to the Said Urn.</li>
                <li>The Said Urn shall be reposed in its rightful respective niche. Under no Circumstances the Said Urn is permitted to be reposed in any other niche notwithstanding consent being granted by another owner of different niche on whatsoever reasons. </li>
                <li>The Purchaser and the owner of the Said Urn is the same person.</li>
                <li>The Said Urn will be removed upon the exercise of the Said Urn and the same is permitted to be reposed back after the funeral services has completed.</li>
                <li>You have the sole discretion as to whether the Said Urn containing the cremated ash is reposed back to the Said Niche or otherwise.</li>
              </ul>
              <h3 class="text-center weight-400 fs-14 black-font">
                本人同意遵照此声明所载之上述规则与条件
              </h3>
            </div>
            <h3 class="text-center black-font weight-400 fs-14 mt-1">
              备注： 如对条规的中文译本有任何诠释上之争议，将以英文译本为解释标准
            </h3>
            <div class="border mt-2">
              <b-row>
                <b-col
                  md="6"
                  class="px-2 py-2 border-right border-right-none"
                >
                  <p class="d-form-signature-title">Purchaser / Authorised Representative Signatory & Date
                    <br>
                    <span>购者/受委托领瓮人签名和日期</span>
                  </p>
                  <div
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.authorized-sign-modal
                    class="form-signature-box"
                    style="width: 60%"
                  >
                    <div class="form-signature-content">
                      <span
                        v-if="!authorizedSign"
                        class="form-signature-text"
                      >
                        Sign here
                      </span>
                      <b-img
                        v-else
                        :src="authorizedSignSrc"
                        alt="authorized-sign"
                      />
                    </div>
                  </div>
                  <div class="signature-date-block">
                    <p>Name 姓名: </p>
                    <p>Date 日期: </p>
                  </div>
                </b-col>
                <b-col
                  md="6"
                  class="mt-2 px-1"
                >
                  <b-form-group
                    label="Remark 备注:"
                    label-for="input-default"
                    class="mr-1 remarks"
                    label-class="fs-12 weight-500"
                  >
                    <div
                      class="empty-input__textarea mh-105"
                    >
                      <p />
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div class="border mt-4">
              <h5 class="border-bottom black-font weight-400 fs-14 text-center py-1 text-uppercase">
                For office use 公务用栏
              </h5>
              <b-row>
                <b-col
                  md="6"
                  class="px-2 py-2"
                >
                  <p class="d-form-signature-title">
                    Verified By 确认人员
                  </p>
                  <div
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.verified-by-sign-modal
                    class="form-signature-box"
                  >
                    <div class="form-signature-content">
                      <span
                        v-if="!verifiedBySign"
                        class="form-signature-text"
                      >
                        Sign here
                      </span>
                      <b-img
                        v-else
                        :src="verifiedBySignSrc"
                        alt="authorized-sign"
                      />
                    </div>
                  </div>
                  <div class="signature-date-block">
                    <p>Name 姓名: </p>
                    <p>Date 日期: </p>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <!-- authorized signature modal -->
    <b-modal
      id="authorized-sign-modal"
      ref="authorized-sign-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Authorized Signature"
      @ok="closeAuthorizedSignModal"
      @cancel="closeAuthorizedSignModal"
    >
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          @click="clearAuthorizedSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveAuthorizedSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ authorized signature modal -->
    <!-- verified by signature modal -->
    <b-modal
      id="verified-by-sign-modal"
      ref="verified-by-sign-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Verified By"
      @ok="closeVerifiedBySignModal"
      @cancel="closeVerifiedBySignModal"
    >
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          @click="clearVerifiedSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveVerifiedSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ verified by signature modal -->
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BImg, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BImg,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: {
    digitalForm: {
      type: Object,
      required: true,
    },
    topSpacing: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      gender: [],
      genderOptions: [
        { text: 'Male 男', value: 'Male 男' },
        { text: 'Female 女', value: 'Female 女' },
      ],
      authorizedSign: false,
      verifiedBySign: false,
      authorizedSignSrc: '',
      verifiedBySignSrc: '',
      options: {
        penColor: '#000000',
        onBegin: () => { this.$refs.signaturePad.resizeCanvas() },
      },
    }
  },
  methods: {
    closeAuthorizedSignModal() {
      this.$root.$emit('bv::hide::modal', 'authorized-sign-modal', '')
    },
    closeVerifiedBySignModal() {
      this.$root.$emit('bv::hide::modal', 'verified-by-sign-modal', '')
    },
    clearAuthorizedSign() {
      this.$refs.signaturePad.clearSignature()
    },
    saveAuthorizedSign() {
      const { data } = this.$refs.signaturePad.saveSignature()
      this.authorizedSignSrc = data
      if (data) {
        this.$root.$emit('bv::hide::modal', 'authorized-sign-modal', '')
        this.authorizedSign = true
      }
    },
    clearVerifiedSign() {
      this.$refs.signaturePad.clearSignature()
    },
    saveVerifiedSign() {
      const { data } = this.$refs.signaturePad.saveSignature()
      this.verifiedBySignSrc = data
      if (data) {
        this.$root.$emit('bv::hide::modal', 'verified-by-sign-modal', '')
        this.verifiedBySign = true
      }
    },
  },
}
</script>
