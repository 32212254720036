<template>

  <div>
    <b-row
      class="content-header"
    >
      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="7"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="custom-header-title float-left pr-1 mb-0 text-capitalize">
              {{ digitalForm.name }}
            </h2>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="urn-form-wrapper m-4 ">
        <div class="orn-form-header d-flex justify-content-between mb-75">
          <div class="d-form-logo_section" />
          <div class="d-form-no_section text-right">
            <h5>
              {{ digitalForm.formNumber }}
            </h5>
            <h5>
              {{ digitalForm.revision }}
            </h5>
          </div>
        </div>
        <div class="urn-repository-order_section">
          <div class="b-form-section-title my-2">
            <h3>an ling / chai ling form <span>安灵/拆灵表格</span></h3>
          </div>
          <div class="mt-2">
            <b-table-simple
              class="dark-border-td"
              bordered
              responsive
            >
              <b-tbody>
                <b-tr>
                  <b-td>
                    <span>Purchaser Details</span>
                    <br>
                    <span>购买者资料</span>
                  </b-td>
                  <b-td>
                    <b-row>
                      <b-col
                        cols="12"
                        md="12"
                      >
                        <b-form-group
                          label-cols="12"
                          label-cols-md="6"
                          label-cols-lg="3"
                          label-for="input-default"
                        >
                          <template #label>
                            <span>Purchaser Name*</span>
                            <br>
                            <span>购买者</span>
                          </template>
                          <div
                            class="empty-input__text"
                          ><p
                            emptyDiv="true"
                            data-text="Enter"
                          /></div>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="12"
                      >
                        <b-form-group
                          label-cols="12"
                          label-cols-md="6"
                          label-cols-lg="3"
                          label-for="input-default"
                        >
                          <template #label>
                            <span>NRIC*</span>
                            <br>
                            <span>身分证号码</span>
                          </template>
                          <div
                            class="empty-input__text"
                          ><p
                            emptyDiv="true"
                            data-text="Enter"
                          /></div>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="12"
                      >
                        <b-form-group
                          label-cols="12"
                          label-cols-md="6"
                          label-cols-lg="3"
                          label-for="input-default"
                        >
                          <template #label>
                            <span>Contact No.</span>
                            <br>
                            <span>联络电话</span>
                          </template>
                          <div
                            class="empty-input__text"
                          ><p
                            emptyDiv="true"
                            data-text="Enter"
                          /></div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>
                    <span>Next-of-Kin Details</span>
                    <br>
                    <span>购买者至亲资料</span>
                  </b-td>
                  <b-td>
                    <b-row>
                      <b-col
                        cols="12"
                        md="12"
                      >
                        <b-form-group
                          label-cols="12"
                          label-cols-md="6"
                          label-cols-lg="3"
                          label-for="input-default"
                        >
                          <template #label>
                            <span>Next of Kin Name*</span>
                            <br>
                            <span>至亲姓名</span>
                          </template>
                          <div
                            class="empty-input__text"
                          ><p
                            emptyDiv="true"
                            data-text="Enter"
                          /></div>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="12"
                      >
                        <b-form-group
                          label-cols="12"
                          label-cols-md="6"
                          label-cols-lg="3"
                          label="NRIC* 身分证号码"
                          label-for="input-default"
                        >
                          <template #label>
                            <span>NRIC*</span>
                            <br>
                            <span>身分证号码</span>
                          </template>
                          <div
                            class="empty-input__text"
                          ><p
                            emptyDiv="true"
                            data-text="Enter"
                          /></div>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="12"
                      >
                        <b-form-group
                          label-cols="12"
                          label-cols-md="6"
                          label-cols-lg="3"
                          label-for="input-default"
                        >
                          <template #label>
                            <span>Contact No.</span>
                            <br>
                            <span>联络电话</span>
                          </template>
                          <div
                            class="empty-input__text"
                          ><p
                            emptyDiv="true"
                            data-text="Enter"
                          /></div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>
                    <span> Niche and Pedestal Details</span>
                    <br>
                    <span>福位或牌位编号</span>
                  </b-td>
                  <b-td>
                    <b-row>
                      <b-col
                        cols="12"
                        md="12"
                      >
                        <b-form-group
                          label-cols="12"
                          label-cols-md="6"
                          label-cols-lg="3"
                          label-for="input-default"
                        >
                          <template #label>
                            <span>Niche*</span>
                            <br>
                            <span>福位编号</span>
                          </template>
                          <div
                            class="empty-input__text"
                          ><p
                            emptyDiv="true"
                            data-text="Enter"
                          /></div>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="12"
                      >
                        <b-form-group
                          label-cols="12"
                          label-cols-md="6"
                          label-cols-lg="3"
                          label-for="input-default"
                        >
                          <template #label>
                            <span>Pedestal No.*</span>
                            <br>
                            <span>牌位编号</span>
                          </template>
                          <div
                            class="empty-input__text"
                          ><p
                            emptyDiv="true"
                            data-text="Enter"
                          /></div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>
                    <span>Particulars of Deceased</span>
                    <br>
                    <span>先人资料</span>
                  </b-td>
                  <b-td>
                    <b-row>
                      <b-col
                        cols="12"
                        md="12"
                      >
                        <b-form-group
                          label-cols="12"
                          label-cols-md="6"
                          label-cols-lg="3"
                          label-for="input-default"
                        >
                          <template #label>
                            <span>Name of Deceased*</span>
                            <br>
                            <span>先人名讳</span>
                          </template>
                          <div
                            class="empty-input__text"
                          ><p
                            emptyDiv="true"
                            data-text="Enter"
                          /></div>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="12"
                      >
                        <b-form-group
                          label-cols="12"
                          label-cols-md="6"
                          label-cols-lg="3"
                          label-for="input-default"
                        >
                          <template #label>
                            <span>Gender*</span>
                            <br>
                            <span>性别</span>
                          </template>
                          <b-form-checkbox-group
                            v-model="gender"
                            :options="genderOptions"
                            name="gender"
                            class="form-custom-input-checkbox"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="12"
                      >
                        <b-form-group
                          label-cols="12"
                          label-cols-md="6"
                          label-cols-lg="3"
                          label-for="input-default"
                        >
                          <template #label>
                            <span>Age*</span>
                            <br>
                            <span>享年</span>
                          </template>
                          <div
                            class="empty-input__text"
                          ><p
                            emptyDiv="true"
                            data-text="Enter"
                          /></div>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="12"
                      >
                        <b-form-group
                          label-cols="12"
                          label-cols-md="6"
                          label-cols-lg="3"
                          label-for="input-default"
                        >
                          <template #label>
                            <span>Departed Date*</span>
                            <br>
                            <span>逝世日期</span>
                          </template>
                          <div
                            class="d-form-input-flex"
                          >
                            <b-input-group class="input-group-merge">
                              <div
                                class="empty-input__text"
                              ><p
                                emptyDivAppend="true"
                                data-text="Enter"
                                data-text-append="Day 日"
                              /></div>
                            </b-input-group>
                            <b-input-group class="input-group-merge">
                              <div
                                class="empty-input__text"
                              ><p
                                emptyDivAppend="true"
                                data-text="Enter"
                                data-text-append="Month 月"
                              /></div>
                            </b-input-group>
                            <b-input-group class="input-group-merge">
                              <div
                                class="empty-input__text"
                              ><p
                                emptyDivAppend="true"
                                data-text="Enter"
                                data-text-append="Year 年"
                              /></div>
                            </b-input-group>
                          </div>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="12"
                      >
                        <b-form-group
                          label-cols="12"
                          label-cols-md="6"
                          label-cols-lg="3"
                          label-for="input-default"
                        >
                          <template #label>
                            <span>Relationship*</span>
                            <br>
                            <span>先人与购买者或至亲关系</span>
                          </template>
                          <div
                            class="empty-input__text"
                          ><p
                            emptyDiv="true"
                            data-text="Enter"
                          /></div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>
                    <span>Service Record</span>
                    <br>
                    <span>服务记录</span>
                  </b-td>
                  <b-td>
                    <b-row>
                      <b-col
                        cols="12"
                        md="12"
                      >
                        <b-form-group
                          label-cols="12"
                          label-cols-md="6"
                          label-cols-lg="3"
                          label-for="input-default"
                        >
                          <template #label>
                            <span>Allocated Lot</span>
                            <br>
                            <span>灵位阁与编号</span>
                          </template>
                          <b-form-input
                            id="input-default"
                            class="form-custom-input"
                            placeholder="Enter"
                            readonly
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div class="d-form-an-ling-block">
                      <h3>An Ling 安灵</h3>
                      <b-row>
                        <b-col
                          cols="12"
                          md="12"
                        >
                          <b-form-group
                            label-cols="12"
                            label-cols-md="5"
                            label-cols-lg="2"
                            label-for="input-default"
                          >
                            <template #label>
                              <span>Date</span>
                              <br>
                              <span>日期</span>
                            </template>
                            <div
                              class="d-form-input-flex"
                            >
                              <b-input-group class="input-group-merge">
                                <div
                                  class="empty-input__text"
                                ><p
                                  emptyDivAppend="true"
                                  data-text="Enter"
                                  data-text-append="Day 日"
                                /></div>
                              </b-input-group>
                              <b-input-group class="input-group-merge">
                                <div
                                  class="empty-input__text"
                                ><p
                                  emptyDivAppend="true"
                                  data-text="Enter"
                                  data-text-append="Month 月"
                                /></div>
                              </b-input-group>
                              <b-input-group class="input-group-merge">
                                <div
                                  class="empty-input__text"
                                ><p
                                  emptyDivAppend="true"
                                  data-text="Enter"
                                  data-text-append="Year 年"
                                /></div>
                              </b-input-group>
                            </div>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row class="mt-2">
                        <b-col
                          cols="12"
                          md="12"
                        >
                          <p>Purchaser / Authorized Representative Signatory
                            <br>
                            <span>购买者/受委托领瓮人签名</span>
                          </p>
                          <div
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            v-b-modal.authorized-sign-modal-one
                            class="form-signature-box"
                          >
                            <div class="form-signature-content">
                              <span
                                v-if="!authorizedSignOne"
                                class="form-signature-text"
                              >
                                Sign here
                              </span>
                              <b-img
                                v-else
                                :src="authorizedSignOneSrc"
                                alt="authorized-sign"
                              />
                            </div>
                          </div>
                          <div class="signature-date-block">
                            <p>Name 姓名: </p>
                            <p>Date 日期: </p>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="d-form-an-ling-block mt-2">
                      <h3>Chai Ling 拆灵</h3>
                      <b-row>
                        <b-col
                          cols="12"
                          md="12"
                        >
                          <b-form-group
                            label-cols="12"
                            label-cols-md="5"
                            label-cols-lg="2"
                            label-for="input-default"
                          >
                            <template #label>
                              <span>Date</span>
                              <br>
                              <span>日期</span>
                            </template>
                            <div
                              class="d-form-input-flex"
                            >
                              <b-input-group class="input-group-merge">
                                <div
                                  class="empty-input__text"
                                ><p
                                  emptyDivAppend="true"
                                  data-text="Enter"
                                  data-text-append="Day 日"
                                /></div>
                              </b-input-group>
                              <b-input-group class="input-group-merge">
                                <div
                                  class="empty-input__text"
                                ><p
                                  emptyDivAppend="true"
                                  data-text="Enter"
                                  data-text-append="Month 月"
                                /></div>
                              </b-input-group>
                              <b-input-group class="input-group-merge">
                                <div
                                  class="empty-input__text"
                                ><p
                                  emptyDivAppend="true"
                                  data-text="Enter"
                                  data-text-append="Year 年"
                                /></div>
                              </b-input-group>
                            </div>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          md="12"
                        >
                          <b-form-group
                            label-cols="12"
                            label-cols-md="5"
                            label-cols-lg="2"
                            label-for="input-default"
                          >
                            <template #label>
                              <span>49 Days</span>
                              <br>
                              <span>尾七</span>
                            </template>
                            <div
                              class="d-form-input-flex"
                            >
                              <b-input-group class="input-group-merge">
                                <div
                                  class="empty-input__text"
                                ><p
                                  emptyDivAppend="true"
                                  data-text="Enter"
                                  data-text-append="Day 日"
                                /></div>
                              </b-input-group>
                              <b-input-group class="input-group-merge">
                                <div
                                  class="empty-input__text"
                                ><p
                                  emptyDivAppend="true"
                                  data-text="Enter"
                                  data-text-append="Month 月"
                                /></div>
                              </b-input-group>
                              <b-input-group class="input-group-merge">
                                <div
                                  class="empty-input__text"
                                ><p
                                  emptyDivAppend="true"
                                  data-text="Enter"
                                  data-text-append="Year 年"
                                /></div>
                              </b-input-group>
                            </div>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          md="12"
                        >
                          <b-form-group
                            label-cols="12"
                            label-cols-md="5"
                            label-cols-lg="2"
                            label-for="input-default"
                          >
                            <template #label>
                              <span>100 Days</span>
                              <br>
                              <span>百日</span>
                            </template>
                            <div
                              class="d-form-input-flex"
                            >
                              <b-input-group class="input-group-merge">
                                <div
                                  class="empty-input__text"
                                ><p
                                  emptyDivAppend="true"
                                  data-text="Enter"
                                  data-text-append="Day 日"
                                /></div>
                              </b-input-group>
                              <b-input-group class="input-group-merge">
                                <div
                                  class="empty-input__text"
                                ><p
                                  emptyDivAppend="true"
                                  data-text="Enter"
                                  data-text-append="Month 月"
                                /></div>
                              </b-input-group>
                              <b-input-group class="input-group-merge">
                                <div
                                  class="empty-input__text"
                                ><p
                                  emptyDivAppend="true"
                                  data-text="Enter"
                                  data-text-append="Year 年"
                                /></div>
                              </b-input-group>
                            </div>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row class="mt-2">
                        <b-col
                          cols="12"
                          md="12"
                        >
                          <p>Purchaser / Authorized Representative Signatory
                            <br>
                            <span>购买者/受委托领瓮人签名</span>
                          </p>
                          <div
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            v-b-modal.authorized-sign-modal-two
                            class="form-signature-box"
                          >
                            <div class="form-signature-content">
                              <span
                                v-if="!authorizedSignTwo"
                                class="form-signature-text"
                              >
                                Sign here
                              </span>
                              <b-img
                                v-else
                                :src="authorizedSignTwoSrc"
                                class="p-2 signature-image"
                                alt="authorized-sign"
                              />
                            </div>
                          </div>
                          <div class="signature-date-block">
                            <p>Name 姓名: </p>
                            <p>Date 日期: </p>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>
                    <span>Other Requests</span>
                    <br>
                    <span>其他需求</span>
                  </b-td>
                  <b-td>
                    <b-row>
                      <b-col
                        cols="12"
                        md="12"
                      >
                        <div
                          class="empty-input__textarea"
                        ><p
                          emptyDiv="true"
                          data-text="Enter"
                        /></div>
                      </b-col>
                    </b-row>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
          <div class="d-form-border mt-4">
            <b-row>
              <b-col
                md="6"
                class="d-form-border-right pr-0"
              >
                <div class="padding-20">
                  <b-form-group
                    label-cols="12"
                    label="Agent Name 代理姓名"
                    label-for="input-default"
                  >
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                  <b-form-group
                    label-cols="12"
                    label="Agent Contact No. 代理联络电话"
                    label-for="input-default"
                  >
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </div>
              </b-col>
              <b-col
                md="6"
                class="pl-0"
              >
                <div class="padding-20">
                  <p class="d-form-signature-title">
                    Service Staff Signature 服务人员签名:
                  </p>
                  <div
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.service-staff-sign-modal
                    class="form-signature-box"
                  >
                    <div class="form-signature-content">
                      <span
                        v-if="!serviceStaffSign"
                        class="form-signature-text"
                      >
                        Sign here
                      </span>
                      <b-img
                        v-else
                        :src="serviceStaffSignSrc"
                        class="p-2 signature-image"
                        alt="authorized-sign"
                      />
                    </div>
                  </div>
                  <div class="signature-date-block">
                    <p>Name 姓名: </p>
                    <p>Date 日期: </p>
                  </div>
                </div></b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-card>
    <!-- authorized signature modal one -->
    <b-modal
      id="authorized-sign-modal-one"
      ref="authorized-sign-modal-one"
      modal-class="status-update-modal"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Authorized Signature"
      @ok="closeAuthorizedSignOneModal"
      @cancel="closeAuthorizedSignOneModal"
    >
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          @click="clearAuthorizedSignOne()"
        >
          Clear
        </b-button>
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveAuthorizedSignOne()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ authorized signature modal one -->
    <!-- authorized signature modal two -->
    <b-modal
      id="authorized-sign-modal-two"
      ref="authorized-sign-modal-two"
      modal-class="status-update-modal"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Authorized Signature"
      @ok="closeAuthorizedSignTwoModal"
      @cancel="closeAuthorizedSignTwoModal"
    >
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          @click="clearAuthorizedSignTwo()"
        >
          Clear
        </b-button>
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveAuthorizedSignTwo()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ authorized signature modal two -->
    <!-- service staff signature modal -->
    <b-modal
      id="service-staff-sign-modal"
      ref="service-staff-sign-modal"
      modal-class="status-update-modal"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Service Staff Signature"
      @ok="closeServiceStaffSignModal"
      @cancel="closeServiceStaffSignModal"
    >
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          @click="clearServiceStaffSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveServiceStaffSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ service staff signature modal -->
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BInputGroup, BFormCheckboxGroup,
  BTableSimple, BTbody, BTr, BTd, BImg, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormCheckboxGroup,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BImg,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    digitalForm: {
      type: Object,
      required: true,
    },
    topSpacing: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      gender: [],
      genderOptions: [
        { text: 'Male 男', value: 'Male 男' },
        { text: 'Female 女', value: 'Female 女' },
      ],
      authorizedSignOne: false,
      authorizedSignOneSrc: '',
      authorizedSignTwo: false,
      authorizedSignTwoSrc: '',
      serviceStaffSign: false,
      serviceStaffSignSrc: '',
      options: {
        penColor: '#000000',
        onBegin: () => { this.$refs.signaturePad.resizeCanvas() },
      },
    }
  },
  methods: {
    closeAuthorizedSignOneModal() {
      this.$root.$emit('bv::hide::modal', 'authorized-sign-modal-one', '')
    },
    clearAuthorizedSignOne() {
      this.$refs.signaturePad.clearSignature()
    },
    saveAuthorizedSignOne() {
      const { data } = this.$refs.signaturePad.saveSignature()
      this.authorizedSignOneSrc = data
      if (data) {
        this.$root.$emit('bv::hide::modal', 'authorized-sign-modal-one', '')
        this.authorizedSignOne = true
      }
    },
    closeAuthorizedSignTwoModal() {
      this.$root.$emit('bv::hide::modal', 'authorized-sign-modal-two', '')
    },
    clearAuthorizedSignTwo() {
      this.$refs.signaturePad.clearSignature()
    },
    saveAuthorizedSignTwo() {
      const { data } = this.$refs.signaturePad.saveSignature()
      this.authorizedSignTwoSrc = data
      if (data) {
        this.$root.$emit('bv::hide::modal', 'authorized-sign-modal-two', '')
        this.authorizedSignTwo = true
      }
    },
    closeServiceStaffSignModal() {
      this.$root.$emit('bv::hide::modal', 'service-staff-sign-modal', '')
    },
    clearServiceStaffSign() {
      this.$refs.signaturePad.clearSignature()
    },
    saveServiceStaffSign() {
      const { data } = this.$refs.signaturePad.saveSignature()
      this.serviceStaffSignSrc = data
      if (data) {
        this.$root.$emit('bv::hide::modal', 'service-staff-sign-modal', '')
        this.serviceStaffSign = true
      }
    },
  },
}
</script>
