<template>

  <div>
    <b-row
      class="content-header"
    >
      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="7"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="custom-header-title float-left pr-1 mb-0">
              {{ digitalForm.name }}
            </h2>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="urn-form-wrapper m-4 ">
        <div class="orn-form-header d-flex justify-content-between mb-75">
          <div class="d-form-logo_section" />
          <div class="d-form-no_section text-right">
            <h5>
              {{ digitalForm.formNumber }}
            </h5>
            <h5>
              {{ digitalForm.revision }}
            </h5>
          </div>
        </div>
        <div class="urn-repository-order_section">
          <div class="b-form-section-title my-2">
            <h3>URN COMPARTMENT PLAQUE ENGRAVING NOTICE <span>福位刻碑表格</span></h3>
          </div>
          <b-row>
            <b-col md="8">
              <div class="d-form-border my-2">
                <div class="d-form-urn-compartment-box-left-section">
                  <b-form-checkbox-group
                    v-model="gender"
                    :options="genderOptions"
                    name="gender"
                    class="form-custom-input-checkbox"
                  />
                </div>
                <div class="d-form-urn-compartment-box-left-main-section">
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        label-cols="12"
                        label-cols-lg="2"
                        label-for="input-default"
                      >
                        <template #label>
                          <span>Deceased:</span>
                          <br>
                          <span>往生者</span>
                        </template>
                        <div
                          class="empty-input__text"
                        ><p
                          emptyDiv="true"
                          data-text="Enter"
                        /></div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        label-cols="12"
                        label-cols-lg="2"
                        label-for="input-default"
                      >
                        <template #label>
                          <span>Native Place:</span>
                          <br>
                          <span>籍贯</span>
                        </template>
                        <div
                          class="empty-input__text"
                        ><p
                          emptyDiv="true"
                          data-text="Enter"
                        /></div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <div class="input-divider-heading-block">
                    <p>
                      Birth date/time 生日/时
                    </p>
                  </div>
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        label-cols="12"
                        label-cols-lg="2"
                        label-for="input-default"
                      >
                        <template #label>
                          <span>Gregorian:</span>
                          <br>
                          <span>阳历</span>
                        </template>
                        <div
                          class="empty-input__text"
                        ><p
                          emptyDiv="true"
                          data-text="Enter"
                        /></div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        label-cols="12"
                        label-cols-lg="2"
                        label-for="input-default"
                      >
                        <template #label>
                          <span>Lunar:</span>
                          <br>
                          <span>农历</span>
                        </template>
                        <div
                          class="d-form-input-flex"
                        >
                          <b-input-group class="input-group-merge">
                            <div
                              class="empty-input__text"
                            ><p
                              emptyDivAppend="true"
                              data-text="Enter"
                              data-text-append="年"
                            /></div>
                          </b-input-group>
                          <b-input-group class="input-group-merge">
                            <div
                              class="empty-input__text"
                            ><p
                              emptyDivAppend="true"
                              data-text="Enter"
                              data-text-append="月"
                            /></div>
                          </b-input-group>
                          <b-input-group class="input-group-merge">
                            <div
                              class="empty-input__text"
                            ><p
                              emptyDivAppend="true"
                              data-text="Enter"
                              data-text-append="日"
                            /></div>
                          </b-input-group>
                          <b-input-group class="input-group-merge">
                            <div
                              class="empty-input__text"
                            ><p
                              emptyDivAppend="true"
                              data-text="Enter"
                              data-text-append="时"
                            /></div>
                          </b-input-group>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <div class="input-divider-heading-block">
                    <p>
                      Death date/time 歿日/时
                    </p>
                  </div>
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        label-cols="12"
                        label-cols-lg="2"
                        label-for="input-default"
                      >
                        <template #label>
                          <span>Gregorian:</span>
                          <br>
                          <span>阳历</span>
                        </template>
                        <div
                          class="empty-input__text"
                        ><p
                          emptyDiv="true"
                          data-text="Enter"
                        /></div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        label-cols="12"
                        label-cols-lg="2"
                        label-for="input-default"
                        append=".00"
                      >
                        <template #label>
                          <span>Lunar:</span>
                          <br>
                          <span>农历</span>
                        </template>
                        <div
                          class="d-form-input-flex"
                        >
                          <b-input-group class="input-group-merge">
                            <div
                              class="empty-input__text"
                            ><p
                              emptyDivAppend="true"
                              data-text="Enter"
                              data-text-append="年"
                            /></div>
                          </b-input-group>
                          <b-input-group class="input-group-merge">
                            <div
                              class="empty-input__text"
                            ><p
                              emptyDivAppend="true"
                              data-text="Enter"
                              data-text-append="月"
                            /></div>
                          </b-input-group>
                          <b-input-group class="input-group-merge">
                            <div
                              class="empty-input__text"
                            ><p
                              emptyDivAppend="true"
                              data-text="Enter"
                              data-text-append="日"
                            /></div>
                          </b-input-group>
                          <b-input-group class="input-group-merge">
                            <div
                              class="empty-input__text"
                            ><p
                              emptyDivAppend="true"
                              data-text="Enter"
                              data-text-append="时"
                            /></div>
                          </b-input-group>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
            <b-col md="4">
              <div class="d-form-border my-2">
                <div class="d-form-photo-status-block">
                  <div class="d-form-heading-block">
                    <h4> Photo Received 照片提供</h4>
                  </div>
                  <b-form-group
                    label="Received By 收件:"
                    label-for="input-default"
                  >
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                  <b-form-group
                    label="Date 日期:"
                    label-for="input-default"
                  >
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="DD/MM/YYYY"
                    /></div>
                  </b-form-group>
                  <div class="d-form-heading-block mt-1">
                    <h4>Photo Returned 照片归还</h4>
                  </div>
                  <b-form-group
                    label="Claimed By 照片领回:"
                    label-for="input-default"
                  >
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                  <b-form-group
                    label="Date 日期:"
                    label-for="input-default"
                  >
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="DD/MM/YYYY"
                    /></div>
                  </b-form-group>
                  <div class="d-form-heading-block mt-1">
                    <h4>Remarks 备注</h4>
                  </div>
                  <div>
                    <p>All Chinese Character on the plaque must be in traditional Chinese text.<br><span>碑文均採繁體</span></p>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label-cols="4"
                label-cols-lg="1"
                label-for="input-default"
              >
                <template #label>
                  <span>Purchaser*</span>
                  <br>
                  <span>购买者</span>
                </template>
                <div
                  class="empty-input__text"
                ><p
                  emptyDiv="true"
                  data-text="Enter"
                /></div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label-cols="4"
                label-cols-lg="2"
                label-for="input-default"
              >
                <template #label>
                  <span>Contact No.</span>
                  <br>
                  <span>联络号码</span>
                </template>
                <div
                  class="empty-input__text"
                ><p
                  emptyDiv="true"
                  data-text="Enter"
                /></div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label-cols="4"
                label-cols-lg="2"
                label-for="input-default"
              >
                <template #label>
                  <span>NRIC*</span>
                  <br>
                  <span>身分证号码</span>
                </template>
                <div
                  class="empty-input__text"
                ><p
                  emptyDiv="true"
                  data-text="Enter"
                /></div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label-cols="4"
                label-cols-lg="1"
                label-for="input-default"
              >
                <template #label>
                  <span>Next of Kin Name*</span>
                  <br>
                  <span>至亲姓名</span>
                </template>
                <div
                  class="empty-input__text"
                ><p
                  emptyDiv="true"
                  data-text="Enter"
                /></div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label-cols="4"
                label-cols-lg="2"
                label-for="input-default"
              >
                <template #label>
                  <span>Contact No.</span>
                  <br>
                  <span>联络号码</span>
                </template>
                <div
                  class="empty-input__text"
                ><p
                  emptyDiv="true"
                  data-text="Enter"
                /></div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label-cols="4"
                label-cols-lg="2"
                label-for="input-default"
              >
                <template #label>
                  <span>NRIC*</span>
                  <br>
                  <span>身分证号码</span>
                </template>
                <div
                  class="empty-input__text"
                ><p
                  emptyDiv="true"
                  data-text="Enter"
                /></div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label-cols="4"
                label-cols-lg="2"
                label-for="input-default"
              >
                <template #label>
                  <span>File No.*</span>
                  <br>
                  <span>文件号码</span>
                </template>
                <div
                  class="empty-input__text"
                ><p
                  emptyDiv="true"
                  data-text="Enter"
                /></div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label-cols="4"
                label-cols-lg="2"
                label-for="input-default"
              >
                <template #label>
                  <span>Niche No.*</span>
                  <br>
                  <span>福位编号</span>
                </template>
                <div
                  class="empty-input__text"
                ><p
                  emptyDiv="true"
                  data-text="Enter"
                /></div>
              </b-form-group>
            </b-col>
          </b-row>   <b-row>
            <b-col md="6">
              <b-form-group
                label-cols="4"
                label-cols-lg="2"
                label-for="input-default"
              >
                <template #label>
                  <span>Agent Name*</span>
                  <br>
                  <span>代理姓名</span>
                </template>
                <div
                  class="empty-input__text"
                ><p
                  emptyDiv="true"
                  data-text="Enter"
                /></div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label-cols="4"
                label-cols-lg="2"
                label-for="input-default"
              >
                <template #label>
                  <span>Agent Contact No.</span>
                  <br>
                  <span>代理联络电话</span>
                </template>
                <div
                  class="empty-input__text"
                ><p
                  emptyDiv="true"
                  data-text="Enter"
                /></div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label-cols="4"
                label-cols-lg="1"
                label-for="input-default"
              >
                <template #label>
                  <span>Remarks</span>
                  <br>
                  <span>备注</span>
                </template>
                <div
                  class="empty-input__text"
                ><p
                  emptyDiv="true"
                  data-text="Enter"
                /></div>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="d-form-warning-text-block">
            <p class="d-form-warning-text">
              Disclaimer: I hereby confirm that all the information and characters herein are correct and authorised Mount Prajna Ltd to engrave the Plaque in accordance to this notice. The Plaque will be available after 30 days from the date of this notice.
            </p>
            <p class="d-form-warning-text">
              声明： 本人确定所附资料正确无误，并请遵照刻碑，本表格签署三十天后，即请安排上碑。
            </p>
          </div>
          <b-row class="mt-5">
            <b-col md="6">
              <p class="d-form-signature-title">Purchaser’s / Next of Kin’s Signatory & Date
                <br>
                <span>购买者或至亲签名</span>
              </p>
              <div
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-modal.purchaser-sign-modal
                class="form-signature-box"
              >
                <div class="form-signature-content">
                  <span
                    v-if="!isPurchaserSign"
                    class="form-signature-text"
                  >
                    Sign here
                  </span>
                  <b-img
                    v-else
                    :src="purchaserSign"
                    alt="authorized-sign"
                  />
                </div>
              </div>
              <div class="signature-date-block">
                <p>Name 姓名: </p>
                <p>Date 日期: </p>
              </div>
            </b-col>
          </b-row>
          <div class="d-form-border mt-4">
            <h5 class="d-form-office-use-title">
              For office use 公务用栏
            </h5>
            <b-row>
              <b-col
                md="6"
                class="d-form-border-right pr-0"
              >
                <div class="padding-20">
                  <p class="d-form-signature-title">
                    Person in Charge 服务人员:
                  </p>
                  <div
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.person-in-charge-sign-modal
                    class="form-signature-box"
                  >
                    <div class="form-signature-content">
                      <span
                        v-if="!personInChargeSign"
                        class="form-signature-text"
                      >
                        Sign here
                      </span>
                      <b-img
                        v-else
                        :src="personInChargeSignSrc"
                        alt="authorized-sign"
                      />
                    </div>
                  </div>
                  <div class="signature-date-block">
                    <p>Name 姓名: </p>
                    <p>Date 日期: </p>
                  </div>
                </div>
              </b-col>
              <b-col
                md="6"
                class="pl-0"
              >
                <div class="padding-20">
                  <p class="d-form-signature-title">
                    System Updated By 系统输入人员
                  </p>
                  <div
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.system-updated-by-sign-modal
                    class="form-signature-box"
                  >
                    <div class="form-signature-content">
                      <span
                        v-if="!systemUpdatedSign"
                        class="form-signature-text"
                      >
                        Sign here
                      </span>
                      <img
                        v-else
                        :src="systemUpdatedSignSrc"
                        alt="authorized-sign"
                      >
                    </div>
                  </div>
                  <div class="signature-date-block">
                    <p>Name 姓名: </p>
                    <p>Date 日期: </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-card>
    <!-- purchaser signature modal -->
    <b-modal
      id="purchaser-sign-modal"
      ref="purchaser-sign-modal"
      modal-class="status-update-modal"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Purchaser's Signature"
      @ok="closePurchaserSignModal"
      @cancel="closePurchaserSignModal"
    >
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          @click="clearPurchaserSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="savePurchaserSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ purchaser signature modal -->
    <!-- person in charge signature modal -->
    <b-modal
      id="person-in-charge-sign-modal"
      ref="person-in-charge-sign-modal"
      modal-class="status-update-modal"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Person in Charge Signature"
      @ok="closePersonInChargeSignModal"
      @cancel="closePersonInChargeSignModal"
    >
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          @click="clearPersonInChargeSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="savePersonInChargeSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ person in charge signature modal -->
    <!-- system updated signature modal -->
    <b-modal
      id="system-updated-by-sign-modal"
      ref="system-updated-by-sign-modal"
      modal-class="status-update-modal"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="System Updated By Signature"
      @ok="closeSystemUpdatedSignModal"
      @cancel="closeSystemUpdatedSignModal"
    >
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          @click="clearSystemUpdatedSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveSystemUpdatedSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ system updated signature modal -->
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BImg, BButton, BFormGroup, BInputGroup, BFormCheckboxGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BImg,
    BFormGroup,
    BFormCheckboxGroup,
    BInputGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    digitalForm: {
      type: Object,
      required: true,
    },
    topSpacing: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      gender: [],
      genderOptions: [
        { text: 'Male 男', value: 'Male 男' },
        { text: 'Female 女', value: 'Female 女' },
      ],
      isPurchaserSign: false,
      purchaserSign: '',
      personInChargeSign: false,
      personInChargeSignSrc: '',
      systemUpdatedSign: false,
      systemUpdatedSignSrc: '',
      options: {
        penColor: '#000000',
        onBegin: () => { this.$refs.signaturePad.resizeCanvas() },
      },
    }
  },
  methods: {
    closePurchaserSignModal() {
      this.$root.$emit('bv::hide::modal', 'purchaser-sign-modal', '')
    },
    clearPurchaserSign() {
      this.$refs.signaturePad.clearSignature()
    },
    savePurchaserSign() {
      const { data } = this.$refs.signaturePad.saveSignature()
      this.purchaserSign = data
      if (data) {
        this.$root.$emit('bv::hide::modal', 'purchaser-sign-modal', '')
        this.isPurchaserSign = true
      }
    },
    closePersonInChargeSignModal() {
      this.$root.$emit('bv::hide::modal', 'person-in-charge-sign-modal', '')
    },
    clearPersonInChargeSign() {
      this.$refs.signaturePad.clearSignature()
    },
    savePersonInChargeSign() {
      const { data } = this.$refs.signaturePad.saveSignature()
      this.personInChargeSignSrc = data
      if (data) {
        this.$root.$emit('bv::hide::modal', 'person-in-charge-sign-modal', '')
        this.personInChargeSign = true
      }
    },
    closeSystemUpdatedSignModal() {
      this.$root.$emit('bv::hide::modal', 'system-updated-by-sign-modal', '')
    },
    clearSystemUpdatedSign() {
      this.$refs.signaturePad.clearSignature()
    },
    saveSystemUpdatedSign() {
      const { data } = this.$refs.signaturePad.saveSignature()
      this.systemUpdatedSignSrc = data
      if (data) {
        this.$root.$emit('bv::hide::modal', 'system-updated-by-sign-modal', '')
        this.systemUpdatedSign = true
      }
    },
  },
}
</script>
