<template>

  <div>
    <b-row
      class="content-header"
    >
      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="7"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="custom-header-title float-left pr-1 mb-0">
              {{ digitalForm.name }}
            </h2>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="urn-form-wrapper m-4">
        <div class="d-form-section-title-layer">
          <h3>Redemption of Urn From Niche / Ancestral Tablet From Pedestal</h3>
          <h5>(return to company)</h5>
          <h3 class="d-form-title-bottom-text">
            骨瓮 / 神主牌位领出福位声明 （归还公司
          </h3>
        </div>
        <div class="urn-repository-order_section">
          <div
            class="d-form-to-re-section-wrapper"
          >
            <p class="d-form-warning-text">
              To:
            </p>
            <div class="d-form-to-address-block">
              <p class="d-form-warning-text">
                Mount Prajna Ltd
              </p>
              <p class="d-form-warning-text">
                950 Old Choa Chu Kang Road,
              </p>
              <p class="d-form-warning-text">
                Singapore 699816
              </p>
            </div>
          </div>
          <div
            class="d-form-to-re-section-wrapper"
          >
            <p class="d-form-re-text d-form-warning-text">
              Re:
            </p>
            <div class="d-form-full-width">
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-cols="12"
                    label-cols-md="6"
                    label-cols-lg="3"
                    label="REDEMPTION OF URN (NICHE LOCATION)*:"
                    label-for="input-default"
                  >
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-cols="12"
                    label-cols-md="7"
                    label-cols-lg="4"
                    label="REDEMPTION OF ANCESTRAL TABLET (PEDESTAL LOCATION)*:"
                    label-for="input-default"
                  >
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="8">
                  <b-form-group
                    label-cols="12"
                    label-cols-md="6"
                    label-cols-lg="4"
                    label="I as Purchaser/Authorized Representative*,"
                    label-for="input-default"
                  >
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label-cols="12"
                    label-cols-md="4"
                    label-cols-lg="3"
                    label="NRIC*"
                    label-for="input-default"
                  >
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-cols="12"
                    label-cols-md="2"
                    label-cols-lg="1"
                    label="of*"
                    label-for="input-default"
                  >
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="8">
                  <b-form-group
                    label-cols="12"
                    label-cols-md="6"
                    label-cols-lg="4"
                    label="I as Purchaser/Authorized Representative*,"
                    label-for="input-default"
                  >
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label-cols="12"
                    label-cols-md="8"
                    label-cols-lg="2"
                    label="NRIC*"
                    label-for="input-default"
                  >
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-cols="12"
                    label-cols-md="2"
                    label-cols-lg="1"
                    label="of*"
                    label-for="input-default"
                  >
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
          <div class="redemption-warning-text-block">
            <p class="d-form-warning-text">
              is the purchaser / authorized representative / next-of-kin of the above niche / pedestal seek for your consent to remove from the niche above the urn deposited / from the pedestal above the ancestral tablet installed therein.
            </p>
            <p class="d-form-warning-text">
              本人乃新加坡富贵山庄福位 / 神主牌位购买者 / 购买者之至亲 / 购买者之委托人（本人身份证号和购买福位 / 神主牌位如附表），请协助将如数骨瓮 / 神主牌位领出所购福位 / 神主牌位。
            </p>
            <p class="d-form-warning-text">
              I shall indemnify you and keep you fully and completely indemnified against all claims, liabilities demand actions, proceedings costs and expense that you may suffer arising from your removal of the Said Urn /Ancestral Tablet.
            </p>
          </div>
          <div class="redemption-warning-text-block pt-0">
            <p class="d-form-warning-text">
              备注： 如对条规的中文译本有任何诠释上之争议，将以英文译本位解释标准。
            </p>
          </div>
          <div class="d-form-t-and-c-box d-form-border">
            <h3>
              Terms and conditions 规则与条件
            </h3>
            <p>1. The Said Urn / Ancestral Tablet is removed from the Said Niche / Pedestal at no extra costs.</p>
            <p>2. Mount Prajna Ltd. and / or its authorised agent or personnel are not responsible and liable for any loss / damage to the Said Urn / Ancestral Tablet.</p>
            <p>3. The Purchaser and the owner of the Said Urn / Ancestral Tablet is the same person.</p>
            <p>4. Upon removal of the Urn / Ancestral Tablet, this license shall forthwith be deemed determined null and void whereupon the Vendor shall be at liberty to dispose of or deal with the niche in such manner as the Vendor shall in its sole discretion think fit.</p>
            <h4>
              本人同意遵照此声明所载之上述规则与条件
            </h4>
          </div>
          <p class="d-form-warning-text mt-1">
            I, the purchaser / authorized representative / next-of-kin of the above niche, hereby declare that: The Customer Privacy Policy Statement and the Customer Personal Data Confirmation (collectively “Notice”) have been made available to me as required under the Personal Data Protection Act (Act 26 of 2012) and having read and inspected the same hereby agree that I consent to the collection, use and disclosure of my personal data for the purposes listed and in accordance with the terms as set out in the Notice, and confirm that I have the authority to provide the personal data of any third parties for processing by Mount Prajna Lte. and/ or its authorised agent or personnel in accordance with the Notice.</p>
          <b-row class="mt-2">
            <b-col md="6">
              <p class="d-form-signature-title">Purchaser / Authorized Representative Signatory & Date
                <br>
                <span>购买者/受委托领瓮人签名和日期</span>
              </p>
              <div
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-modal.authorized-sign-modal
                class="form-signature-box"
              >
                <div class="form-signature-content">
                  <span
                    v-if="!authorizedSign"
                    class="form-signature-text"
                  >
                    Sign here
                  </span>
                  <b-img
                    v-else
                    :src="authorizedSignSrc"
                    alt="authorized-sign"
                  />
                </div>
              </div>
              <div class="signature-date-block">
                <p>Name 姓名: </p>
                <p>Date 日期: </p>
              </div>
            </b-col>
          </b-row>
          <div class="d-form-border mt-2">
            <h5 class="d-form-office-use-title">
              For office use 公务用栏
            </h5>
            <b-row>
              <b-col
                md="6"
                class="d-form-border-right pr-0"
              >
                <div class="padding-20">
                  <p class="d-form-signature-title">
                    Person in Charge 服务人员:
                  </p>
                  <div
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.person-in-charge-sign-modal
                    class="form-signature-box"
                  >
                    <div class="form-signature-content">
                      <span
                        v-if="!personInChargeSign"
                        class="form-signature-text"
                      >
                        Sign here
                      </span>
                      <b-img
                        v-else
                        :src="personInChargeSignSrc"
                        alt="authorized-sign"
                      />
                    </div>
                  </div>
                  <div class="signature-date-block">
                    <p>Name 姓名: </p>
                    <p>Date 日期: </p>
                  </div>
                </div>
              </b-col>
              <b-col
                md="6"
                class="pl-0"
              >
                <div class="padding-20">
                  <p class="d-form-signature-title">
                    Verified By 确认人员:
                  </p>
                  <div
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.verified-sign-modal
                    class="form-signature-box"
                  >
                    <div class="form-signature-content">
                      <span
                        v-if="!verifiedSign"
                        class="form-signature-text"
                      >
                        Sign here
                      </span>
                      <b-img
                        v-else
                        :src="verifiedSignSrc"
                        alt="authorized-sign"
                      />
                    </div>
                  </div>
                  <div class="signature-date-block">
                    <p>Name 姓名: </p>
                    <p>Date 日期: </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-card>
    <!-- authorized signature modal -->
    <b-modal
      id="authorized-sign-modal"
      ref="authorized-sign-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Authorized Signature"
      @ok="closeAuthorizedSignModal"
      @cancel="closeAuthorizedSignModal"
    >
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          @click="clearAuthorizedSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveAuthorizedSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ authorized signature modal -->
    <!-- person in charge signature modal -->
    <b-modal
      id="person-in-charge-sign-modal"
      ref="person-in-charge-sign-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Person in Charge Signature"
      @ok="closePersonInChargeSignModal"
      @cancel="closePersonInChargeSignModal"
    >
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          @click="clearPersonInChargeSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="savePersonInChargeSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ person in charge signature modal -->
    <!-- verified signature modal -->
    <b-modal
      id="verified-sign-modal"
      ref="verified-sign-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Verified By"
      @ok="closeVerifiedSignModal"
      @cancel="closeVerifiedSignModal"
    >
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          @click="clearVerifiedSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveVerifiedSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ verified signature modal -->
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BImg, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BImg,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    digitalForm: {
      type: Object,
      required: true,
    },
    topSpacing: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      authorizedSign: false,
      authorizedSignSrc: '',
      personInChargeSign: false,
      personInChargeSignSrc: '',
      verifiedSign: false,
      verifiedSignSrc: '',
      options: {
        penColor: '#000000',
        onBegin: () => { this.$refs.signaturePad.resizeCanvas() },
      },
    }
  },
  methods: {
    closeAuthorizedSignModal() {
      this.$root.$emit('bv::hide::modal', 'authorized-sign-modal', '')
    },
    clearAuthorizedSign() {
      this.$refs.signaturePad.clearSignature()
    },
    saveAuthorizedSign() {
      const { data } = this.$refs.signaturePad.saveSignature()
      this.authorizedSignSrc = data
      if (data) {
        this.$root.$emit('bv::hide::modal', 'authorized-sign-modal', '')
        this.authorizedSign = true
      }
    },
    closePersonInChargeSignModal() {
      this.$root.$emit('bv::hide::modal', 'person-in-charge-sign-modal', '')
    },
    clearPersonInChargeSign() {
      this.$refs.signaturePad.clearSignature()
    },
    savePersonInChargeSign() {
      const { data } = this.$refs.signaturePad.saveSignature()
      this.personInChargeSignSrc = data
      if (data) {
        this.$root.$emit('bv::hide::modal', 'person-in-charge-sign-modal', '')
        this.personInChargeSign = true
      }
    },
    closeVerifiedSignModal() {
      this.$root.$emit('bv::hide::modal', 'verified-sign-modal', '')
    },
    clearVerifiedSign() {
      this.$refs.signaturePad.clearSignature()
    },
    saveVerifiedSign() {
      const { data } = this.$refs.signaturePad.saveSignature()
      this.verifiedSignSrc = data
      if (data) {
        this.$root.$emit('bv::hide::modal', 'verified-sign-modal', '')
        this.verifiedSign = true
      }
    },
  },
}
</script>
