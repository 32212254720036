<template>
  <div>
    <b-row
      class="content-header"
    >
      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="7"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="custom-header-title float-left pr-1 mb-0">
              {{ digitalForm.name }}
            </h2>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row
      class="content-header v-sticky-sidebar-container service_request_task mx-3"
      style="background: #E0E8F2; border-radius: 6px;"
    >
      <b-col
        class="content-header-right d-md-block df-padding"
        md="12"
        cols="12"
      >
        <!-- Table Container Card -->
        <b-card
          no-body
          class="mb-0"
        >
          <div class="orn-form-wrapper m-4">
            <div class="d-flex mb-1 justify-content-between">
              <div class="d-flex">
                <div class="d-form-logo_section" />
                <div
                  class="urn_repository-company-details"
                >
                  <div class="urn_repository-company-title">
                    <h2>
                      <span>
                        富貴山莊
                      </span>
                      <br>
                      Mount Prajna Ltd.
                    </h2>
                  </div>
                  <div class="urn_repository-company-tagline">
                    <p> (Co. Reg. No. 200401183W)</p>
                    <p>(GST Group Reg. No. M90364395L)</p>
                  </div>
                </div>
              </div>
              <div class="d-form-no_section">
                <h5>
                  {{ digitalForm.formNumber }}
                </h5>
                <h5>
                  {{ digitalForm.revision }}
                </h5>
              </div>
            </div>
            <div class="urn-repository-order_section">
              <div class="b-form-section-title">
                <h3>
                  Urn Repository order
                </h3>
              </div>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="2"
                    label="At the behest of*: 在购者"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="2"
                    label="NRIC*: 身份证号码"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <div class="d-flex">
                      <div
                        class="empty-input__text"
                      ><p
                        emptyDiv="true"
                        data-text="Enter"
                      /></div>
                      <span class="urn_repository-purchaser_text">(Purchaser)</span>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="2"
                    label="Or Next of Kin: 或是其至亲"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="2"
                    label="NRIC*: 身份证号码"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <p class="urn_repository-paragraph">
                    that niche described hereunder is now available for the deposit of urn containing the cinerary remains of the purchaser or that of his nominee named below:
                  </p>
                  <p class="urn_repository-paragraph">
                    的嘱咐下，以下所列明的骨灰灵位，如今已可以按放瓮内装有购者或其受益人火化骨灰的骨灰瓮 。
                  </p>
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="2"
                    label="File No. 文件号码*:"
                    label-for="input-default"
                    class="mt-2 align-items-center"
                  >
                    <template #label>
                      <span>File No.*:</span>
                      <br>
                      <span>文件号码</span>
                    </template>
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="2"
                    label="Niche No. 福位编号*:"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <template #label>
                      <span>Niche No.*:</span>
                      <br>
                      <span>福位编号</span>
                    </template>
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <div class="urn_repository-text-box">
                    <p>The niche is for depositing cinerary urn only. The Management shall not be liable to the purchaser in any manner whatsoever resulting from or arising out of any loss of or damage to any other material chattels or things deposited in the niche. </p>
                    <p>骨灰位仅供安置骨灰瓮之用，其它物件一概不可随瓮安置。若有意外，本公司将不负责赔赏有关物件所蒙受的损失与破坏.</p>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="urn-form-particular_section">
              <div class="urn_repository-particular">
                <h3>
                  PARTICULARS
                </h3>
              </div>
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="1"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <template #label>
                      <div
                        class="urn-repository-order__label"
                      >
                        <span>1.</span>
                        <div>
                          <span>Name of Beneficiary*:</span>
                          <br>
                          <span>收益者姓名</span>
                        </div>
                      </div>
                    </template>
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="2"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <template #label>
                      <div
                        class="urn-repository-order__label"
                      >
                        <span>2.</span>
                        <div>
                          <span>Relationship*:</span>
                          <br>
                          <span>关系</span>
                        </div>
                      </div>
                    </template>
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="2"
                    label="Age*: 年龄"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="2"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <template #label>
                      <div
                        class="urn-repository-order__label"
                      >
                        <span>3.</span>
                        <div>
                          <span class="">Departed on*:</span>
                          <br>
                          <span>逝世日期</span>
                        </div>
                      </div>
                    </template>
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="2"
                    label="Birth Date*: 出生日期"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="2"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <template #label>
                      <div
                        class="urn-repository-order__label"
                      >
                        <span>4.</span>
                        <div>
                          <span>Religion*:</span>
                          <br>
                          <span>宗教</span>
                        </div>
                      </div>
                    </template>
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="2"
                    label="Gender*: 性別"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <b-form-checkbox-group
                      v-model="gender"
                      :options="genderOptions"
                      name="gender"
                      class="form-custom-input-checkbox urn_repository-checkbox-label"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="1"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <template #label>
                      <div
                        class="urn-repository-order__label"
                      >
                        <span>5.</span>
                        <div>
                          <span>Undertaker*:</span>
                          <br>
                          <span>寿板店</span>
                        </div>
                      </div>
                    </template>
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="1"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <template #label>
                      <div
                        class="urn-repository-order__label"
                      >
                        <span>6.</span>
                        <div>
                          <span>Place of Cremation*:</span>
                          <br>
                          <span>焚化地点</span>
                        </div>
                      </div>
                    </template>
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="1"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <template #label>
                      <div
                        class="urn-repository-order__label"
                      >
                        <span>7.</span>
                        <div>
                          <span>Date of Cremation*:</span>
                          <br>
                          <span>焚化日期</span>
                        </div>
                      </div>
                    </template>
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="1"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <template #label>
                      <div
                        class="urn-repository-order__label"
                      >
                        <span>8.</span>
                        <div>
                          <span>Service Address*:</span>
                          <br>
                          <span>治丧处</span>
                        </div>
                      </div>
                    </template>
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="8">
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="2"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <template #label>
                      <div
                        class="urn-repository-order__label"
                      >
                        <span>9.</span>
                        <div>
                          <span class="">Date of Installation:</span>
                          <br>
                          <span>安位日期</span>
                        </div>
                      </div>
                    </template>
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="2"
                    label="Contact No.: 联络号码"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="8">
                  <b-form-checkbox-group>
                    <b-form-checkbox
                      value="1st Installation"
                    >
                      1st Installation
                    </b-form-checkbox>
                    <b-form-checkbox
                      value="10"
                    >
                      Subsequent Installation Fee $0.00
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="2"
                    label="Receipt No.: 收据号码"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <div
                      class="empty-input__text"
                    ><p
                      emptyDiv="true"
                      data-text="Enter"
                    /></div>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="urn_repository-bottom_tagline">
                <p>*All prices are inclusive of prevailing goods and services tax imposed by the government of Singapore. *以上价格已包含消费税</p>
              </div>
              <b-row>
                <b-col md="6">
                  <p class="d-form-signature-title">
                    Purchaser’s / Next of Kin’s Signatory & Date
                    <br>
                    <span>购买者或至亲签名</span></p>
                  <div
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.purchaser-sign-modal
                    class="form-signature-box"
                  >
                    <div class="form-signature-content">
                      <span
                        v-if="!isPurchaserSign"
                        class="form-signature-text"
                      >
                        Sign here
                      </span>
                      <b-img
                        v-else
                        :src="purchaserSign"
                        alt="sign"
                      />
                    </div>
                  </div>
                  <div class="signature-date-block">
                    <p>Name 姓名: </p>
                    <p>Date 日期: </p>
                  </div>
                </b-col>
                <b-col md="6">
                  <p class="d-form-signature-title">
                    Authorised Name
                    <br>
                    <span>礼仪师姓名</span>
                  </p>
                  <div
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.authorized-sign-modal
                    class="form-signature-box"
                  >
                    <div class="form-signature-content">
                      <span
                        v-if="!authorizedSign"
                        class="form-signature-text"
                      >
                        Sign here
                      </span>
                      <b-img
                        v-else
                        :src="authorizedSignSrc"
                        alt="authorized-sign"
                      />
                    </div>
                  </div>
                  <div class="signature-date-block">
                    <p>Name 姓名: </p>
                    <p>Date 日期: </p>
                  </div>
                </b-col>
              </b-row>
              <div class="border mt-2">
                <b-row>
                  <b-col
                    md="6"
                    class="px-2 py-2 border-right"
                  >
                    <p class="d-form-signature-title">
                      Installation Service Handled & Witnessed By:
                      <br>
                      <span>安装服务由以下人员处理和见证</span>
                    </p>
                    <div
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      v-b-modal.witnessed-sign-modal
                      class="form-signature-box"
                    >
                      <div class="form-signature-content">
                        <span
                          v-if="!witnessedSign"
                          class="form-signature-text"
                        >
                          Sign here
                        </span>
                        <img
                          v-else
                          :src="witnessedSignSrc"
                          alt="authorized-sign"
                        >
                      </div>
                    </div>
                    <div class="signature-date-block">
                      <p>Name 姓名: </p>
                      <p>Date 日期: </p>
                    </div>
                  </b-col>
                  <b-col
                    md="6"
                    class="px-2 mt-2"
                  >
                    <b-form-checkbox-group
                      v-model="need"
                      :options="needOptions"
                      name="need"
                      class="form-custom-input-checkbox urn_repository-checkbox-label"
                    />
                    <b-form-group
                      label="Remark 备注:"
                      label-for="input-default"
                    >
                      <div
                        class="empty-input__textarea"
                      ><p
                        emptyDiv="true"
                        data-text="Add remark"
                      /></div>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <!-- purchaser signature modal -->
    <b-modal
      id="purchaser-sign-modal"
      ref="purchaser-sign-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Purchaser's Signature"
      @ok="closePurchaserSignModal"
      @cancel="closePurchaserSignModal"
    >
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          @click="clearPurchaserSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="savePurchaserSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ purchaser signature modal -->
    <!-- authorized signature modal -->
    <b-modal
      id="authorized-sign-modal"
      ref="authorized-sign-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Authorized Signature"
      @ok="closeAuthorizedSignModal"
      @cancel="closeAuthorizedSignModal"
    >
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          @click="clearAuthorizedSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveAuthorizedSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ authorized signature modal -->
    <!-- witnessed signature modal -->
    <b-modal
      id="witnessed-sign-modal"
      ref="witnessed-sign-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Installation Service Handled & Witnessed Signature"
      @ok="closeWitnessedSignModal"
      @cancel="closeWitnessedSignModal"
    >
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          @click="clearWitnessedSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveWitnessedSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ witnessed signature modal -->
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BImg, BFormGroup, BFormCheckboxGroup, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BImg,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  props: {
    digitalForm: {
      type: Object,
      required: true,
    },
    topSpacing: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      gender: [],
      genderOptions: [
        { text: 'Male 男', value: 'Male 男' },
        { text: 'Female 女', value: 'Female 女' },
      ],
      need: [],
      needOptions: [
        { text: 'As-Need', value: 'as-need' },
        { text: 'Pre-Needed', value: 'pre-needed' },
      ],
      isPurchaserSign: false,
      purchaserSign: '',
      authorizedSign: false,
      authorizedSignSrc: '',
      witnessedSign: false,
      witnessedSignSrc: '',
      options: {
        penColor: '#000000',
        onBegin: () => { this.$refs.signaturePad.resizeCanvas() },
      },
    }
  },
  methods: {
    closePurchaserSignModal() {
      this.$root.$emit('bv::hide::modal', 'purchaser-sign-modal', '')
    },
    clearPurchaserSign() {
      this.$refs.signaturePad.clearSignature()
    },
    savePurchaserSign() {
      const { data } = this.$refs.signaturePad.saveSignature()
      this.purchaserSign = data
      if (data) {
        this.$root.$emit('bv::hide::modal', 'purchaser-sign-modal', '')
        this.isPurchaserSign = true
      }
    },
    closeAuthorizedSignModal() {
      this.$root.$emit('bv::hide::modal', 'authorized-sign-modal', '')
    },
    clearAuthorizedSign() {
      this.$refs.signaturePad.clearSignature()
    },
    saveAuthorizedSign() {
      const { data } = this.$refs.signaturePad.saveSignature()
      this.authorizedSignSrc = data
      if (data) {
        this.$root.$emit('bv::hide::modal', 'authorized-sign-modal', '')
        this.authorizedSign = true
      }
    },
    closeWitnessedSignModal() {
      this.$root.$emit('bv::hide::modal', 'witnessed-sign-modal', '')
    },
    clearWitnessedSign() {
      this.$refs.signaturePad.clearSignature()
    },
    saveWitnessedSign() {
      const { data } = this.$refs.signaturePad.saveSignature()
      this.witnessedSignSrc = data
      if (data) {
        this.$root.$emit('bv::hide::modal', 'witnessed-sign-modal', '')
        this.witnessedSign = true
      }
    },
  },
}
</script>
