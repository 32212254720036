<template>
  <div class="add__task">
    <urn-storage-request
      v-if="digitalForm.name == 'Request for Urn Repository For storage purpose only'"
      :digital-form="digitalForm"
      :top-spacing="topSpacing"
    />
    <urn-repository-order
      v-if="digitalForm.name == 'Urn Repository Order'"
      :digital-form="digitalForm"
      :top-spacing="topSpacing"
    />
    <ancestral-tablets-engraving
      v-if="digitalForm.name == 'Ancestral Tablets Engraving Notice'"
      :digital-form="digitalForm"
      :top-spacing="topSpacing"
    />
    <pedestal-installation-order
      v-if="digitalForm.name == 'Pedestal Installation Order'"
      :digital-form="digitalForm"
      :top-spacing="topSpacing"
    />
    <service-form
      v-if="digitalForm.name == 'Service Form'"
      :digital-form="digitalForm"
      :top-spacing="topSpacing"
    />
    <redemption-imported-urn
      v-if="digitalForm.name == 'Redemption of Imported Urn Form'"
      :digital-form="digitalForm"
      :top-spacing="topSpacing"
    />
    <redemption-of-ornament
      v-if="digitalForm.name == 'Redemption of Ornament Form'"
      :digital-form="digitalForm"
      :top-spacing="topSpacing"
    />
    <redemption-of-urn
      v-if="digitalForm.name == 'Redemption of Urn from Niche / Ancestral Tablet From Pedestal'"
      :digital-form="digitalForm"
      :top-spacing="topSpacing"
    />
    <redemption-of-urn-return
      v-if="digitalForm.name == 'Redemption of Urn from Niche / Ancestral Tablet From Pedestal (Return to Company)'"
      :digital-form="digitalForm"
      :top-spacing="topSpacing"
    />
    <urn-compartment-plaque-engraving
      v-if="digitalForm.name == 'Urn Compartment Plaque Engraving Notice'"
      :digital-form="digitalForm"
      :top-spacing="topSpacing"
    />

    <anling-chai-ling
      v-if="digitalForm.name == 'Anling / Chai Ling Form'"
      :digital-form="digitalForm"
      :top-spacing="topSpacing"
    />

    <customer-feedback
      v-if="digitalForm.name == 'Customer’s Comment Form'"
      :digital-form="digitalForm"
      :top-spacing="topSpacing"
    />
  </div>
</template>

<script>
import {
  VBTooltip, VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

import UrnStorageRequest from './UrnStorageRequest.vue'
import UrnRepositoryOrder from './UrnRepositoryOrder.vue'
import AncestralTabletsEngraving from './AncestralTabletsEngraving.vue'
import AnlingChaiLing from './AnlingChaiLing.vue'
import PedestalInstallationOrder from './PedestalInstallationOrder.vue'
import ServiceForm from './ServiceForm.vue'
import RedemptionImportedUrn from './RedemptionImportedUrn.vue'
import RedemptionOfOrnament from './RedemptionOfOrnament.vue'
import RedemptionOfUrn from './RedemptionOfUrn.vue'
import RedemptionOfUrnReturn from './RedemptionOfUrnReturn.vue'
import UrnCompartmentPlaqueEngraving from './UrnCompartmentPlaqueEngraving.vue'
import CustomerFeedback from './CustomerFeedback.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    UrnStorageRequest,
    UrnRepositoryOrder,
    AncestralTabletsEngraving,
    AnlingChaiLing,
    PedestalInstallationOrder,
    ServiceForm,
    RedemptionImportedUrn,
    RedemptionOfUrn,
    RedemptionOfUrnReturn,
    UrnCompartmentPlaqueEngraving,
    CustomerFeedback,
    RedemptionOfOrnament,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  data() {
    return {
      canViewThisAction,
      topSpacing: 135,
      digitalForm: {},

      required,
    }
  },
  created() {
    this.$http.get(`operation/digital-form/${this.$route.params.id}`)
      .then(response => {
        this.digitalForm = response.data || {}
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
}
</script>

<style scoped>
  .timeline-variant-info .timeline-item-point {
    background-color: #00cfe8 !important;
  }
  .low-opacity {
    opacity: 0.6;
  }
  .high-opacity div {
    opacity: 1 !important;
  }
  .padding-two {
    padding: 2rem !important;
  }
  .padding-one {
    padding: 1.5rem !important;
  }
  .shadow-background {
    background: #F5F8FB;
  }
  .purple-button {
    background: #9B5899 !important;
  }
  .purple-row {
    background: rgba(155, 88, 153, 0.12);
  }
  .no-background {
    background: none !important;
  }
  .background-card {
    background: #9FB8D8;
    border-radius: 6px;
  }

  .red-trash-button {
    color: #D91B35 !important;
  }
  .shadow-body {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06) !important;
    border-radius: 6px;
    padding: 1.5rem !important;
  }

  .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
    border-right-width: 1px !important;
  }

  .link-tag {
    color: #104D9D;
  }

  .autosuggest__results-container .autosuggest__results {
    background-color: #fff;
    margin-top: 1rem;
    border-radius: 0.5rem;
    -webkit-box-shadow: 0 15px 30px 0 rgba(0,0,0,.11),0 5px 15px 0 rgba(0,0,0,.08)!important;
    box-shadow: 0 15px 30px 0 rgba(0,0,0,.11),0 5px 15px 0 rgba(0,0,0,.08)!important;
  }
  .autosuggest__results-container .autosuggest__results {
    position: absolute;
    width: 100%;
    overflow-y: auto;
    max-height: 40vh;
  }
</style>
